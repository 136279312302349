import { createSlice } from '@reduxjs/toolkit';
import { TIME_PER_QUESTION_MILLISEC } from '../utils/constants';

export const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    startTime: null as number | null,
    timeLeft: 0,
    timeExpired: false,
  },
  reducers: {
    startTimer: (state, action) => {
      const questionsCount = action.payload;
      state.timeLeft = TIME_PER_QUESTION_MILLISEC * questionsCount;
      state.startTime = Date.now();
    },
    decrementTime: (state) => {
      if (!state.startTime) return;

      const elapsedTime = Date.now() - state.startTime;
      const newTimeLeft = state.timeLeft - elapsedTime;

      if (newTimeLeft > 0) {
        state.timeLeft = newTimeLeft;
        state.startTime = Date.now();
      } else {
        state.timeLeft = 0;
        state.timeExpired = true;
      }
    },
    resetTimer: (state, action) => {
      const questionsCount = action.payload;
      state.timeLeft = TIME_PER_QUESTION_MILLISEC * questionsCount;
      state.timeExpired = false;
      state.startTime = null;
    },
  },
});

export const { startTimer, decrementTime, resetTimer } = timerSlice.actions;
export default timerSlice.reducer;
