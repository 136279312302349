import { TIconProps } from "../../utils/types";

export const ArrowAccordion = ({ onClick }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="13"
      viewBox="0 0 24 13"
      fill="white"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.11.44A1.5,1.5,0,0,0,10,.44L.44,10a1.49,1.49,0,0,0,0,2.12,1.51,1.51,0,0,0,2.12,0l8.49-8.49,8.48,8.49A1.5,1.5,0,0,0,21.65,10Z"
      />
    </svg>
  );
};
