import { useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import { IModal } from "../../utils/types";
import { CloseIcon } from "../../ui/icons";
import { ModalOverlay } from "../modal-overlay/Modal-overlay";

const modalsContainer = document.querySelector("#modals") as HTMLElement;

export function Modal({ close, children }: IModal) {

  const handleEscKeydown = (evt: { key: string }) => {
    if (evt.key === 'Escape') {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeydown);

    return () => {
      document.removeEventListener("keydown", handleEscKeydown);
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div className={styles.modal}>
        <div className={styles.close}>
          <CloseIcon onClick={close} />
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
      <ModalOverlay onClick={close} />
    </>,
    modalsContainer
  );
};
