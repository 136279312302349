import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISectionItem } from "../utils/types";

const initialState = {
  sectionNameEn: '',
  sectionNameRu: '',
  id: ''
};

const sectionSelectSlice = createSlice({
  name: 'sectionSelect',
  initialState,
  reducers: {
    setSection(state, action: PayloadAction<ISectionItem>) {
      state.sectionNameEn = action.payload.sectionNameEn;
      state.sectionNameRu = action.payload.sectionNameRu;
      state.id = action.payload._id!;
    },
    resetSection(state) {
      state.sectionNameEn = initialState.sectionNameEn;
      state.sectionNameRu= initialState.sectionNameRu;
      state.id = initialState.id;
    }
  }
});

export const { setSection, resetSection } = sectionSelectSlice.actions;

export default sectionSelectSlice.reducer;
