import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuthState, TUser } from '../../utils/types';
import { register, login, autoAuth, updateUserPassword, deleteUserAccount, forgotPassword, resetPassword, confirmRegister } from './authThunks';

const initialState: TAuthState = {
  user: null,
  registerRequest: false,
  registerFailed: false,
  registerSuccess: false,
  loginRequest: false,
  loginFailed: false,
  loginSuccess: false,
  updateUserPasswordRequest: false,
  updateUserPasswordFailed: false,
  updateUserPasswordSuccess: false,
  deleteUserRequest: false,
  deleteUserFailed: false,
  deleteUserSuccess: false,
  forgotPasswordRequest: false,
  forgotPasswordFailed: false,
  forgotPasswordSuccess: false,
  resetPasswordRequest: false,
  resetPasswordFailed: false,
  resetPasswordSuccess: false,
  confirmRegisterRequest: false,
  confirmRegisterFailed: false,
  confirmRegisterSuccess: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    confirmRegisterRequest: (state) => {
      state.confirmRegisterRequest = true;
      state.confirmRegisterFailed = false;
      state.confirmRegisterSuccess = false;
    },
    confirmRegisterSuccess: (state, action: PayloadAction<TUser['user']>) => {
      state.confirmRegisterFailed = false;
      state.user = action.payload;
      state.confirmRegisterRequest = false;
      state.confirmRegisterSuccess = true;
    },
    confirmRegisterFailed: (state) => {
      state.confirmRegisterFailed = true;
      state.confirmRegisterRequest = false;
      state.confirmRegisterSuccess = false;
    },
    registerRequest: (state) => {
      state.registerRequest = true;
      state.registerFailed = false;
      state.registerSuccess = false;
    },
    registerSuccess: (state) => {
      state.registerFailed = false;
      state.registerRequest = false;
      state.registerSuccess = true;
    },
    registerFailed: (state) => {
      state.registerFailed = true;
      state.registerRequest = false;
      state.registerSuccess = false;
    },
    logOut: (state) => {
      state.user = null;
      state.registerRequest = false;
      state.registerFailed = false;
      state.registerSuccess = false;
      state.loginRequest = false;
      state.loginFailed = false;
      state.loginSuccess = false;
    },
    loginRequest: (state) => {
      state.loginRequest = true;
      state.loginFailed = false;
      state.loginSuccess = false;
    },
    loginSuccess: (state, action: PayloadAction<TUser['user']>) => {
      state.loginFailed = false;
      state.user = action.payload;
      state.loginRequest = false;
      state.loginSuccess = true;
      state.registerSuccess = false;
    },
    loginFailed: (state) => {
      state.loginFailed = true;
      state.loginRequest = false;
      state.loginSuccess = false;
    },
    updateUserPasswordRequest: (state) => {
      state.updateUserPasswordRequest = true;
      state.updateUserPasswordFailed = false;
      state.updateUserPasswordSuccess = false;
    },
    updateUserPasswordSuccess: (state) => {
      state.updateUserPasswordRequest = false;
      state.updateUserPasswordFailed = false;
      state.updateUserPasswordSuccess = true;
    },
    updateUserPasswordFailed: (state) => {
      state.updateUserPasswordRequest = false;
      state.updateUserPasswordFailed = true;
      state.updateUserPasswordSuccess = false;
    },
    deleteUserRequest: (state) => {
      state.deleteUserRequest = true;
      state.deleteUserFailed = false;
      state.deleteUserSuccess = false;
    },
    deleteUserSuccess: (state) => {
      state.user = null;
      state.registerRequest = false;
      state.registerFailed = false;
      state.registerSuccess = false;
      state.loginRequest = false;
      state.loginFailed = false;
      state.loginSuccess = false;
      state.updateUserPasswordRequest = false;
      state.updateUserPasswordFailed = false;
      state.updateUserPasswordSuccess = false;
      state.deleteUserRequest = false;
      state.deleteUserFailed = false;
      state.deleteUserSuccess = true;
    },
    deleteUserFailed: (state) => {
      state.deleteUserRequest = false;
      state.deleteUserFailed = true;
      state.deleteUserSuccess = false;
    },
    forgotPasswordRequest: (state) => {
      state.forgotPasswordRequest = true;
      state.forgotPasswordFailed = false;
      state.forgotPasswordSuccess = false;
    },
    forgotPasswordSuccess: (state) => {
      state.forgotPasswordRequest = false;
      state.forgotPasswordFailed = false;
      state.forgotPasswordSuccess = true;
    },
    forgotPasswordFailed: (state) => {
      state.forgotPasswordRequest = false;
      state.forgotPasswordFailed = true;
      state.forgotPasswordSuccess = false;
    },
    resetPasswordRequest: (state) => {
      state.resetPasswordRequest = true;
      state.resetPasswordFailed = false;
      state.resetPasswordSuccess = false;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordRequest = false;
      state.resetPasswordFailed = false;
      state.resetPasswordSuccess = true;
    },
    resetPasswordFailed: (state) => {
      state.resetPasswordRequest = false;
      state.resetPasswordFailed = true;
      state.resetPasswordSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmRegister.pending, (state) => {
        state.confirmRegisterRequest = true;
        state.confirmRegisterFailed = false;
        state.confirmRegisterSuccess = false;
      })
      .addCase(confirmRegister.fulfilled, (state, action) => {
        state.confirmRegisterFailed = false;
        state.user = action.payload.user;
        state.confirmRegisterRequest = false;
        state.confirmRegisterSuccess = true;
      })
      .addCase(confirmRegister.rejected, (state) => {
        state.confirmRegisterFailed = true;
        state.confirmRegisterRequest = false;
        state.confirmRegisterSuccess = false;
      })
      .addCase(register.pending, (state) => {
        state.registerRequest = true;
        state.registerFailed = false;
        state.registerSuccess = false;
      })
      .addCase(register.fulfilled, (state) => {
        state.registerFailed = false;
        state.registerRequest = false;
        state.registerSuccess = true;
      })
      .addCase(register.rejected, (state) => {
        state.registerFailed = true;
        state.registerRequest = false;
        state.registerSuccess = false;
      })
      .addCase(login.pending, (state) => {
        state.loginRequest = true;
        state.loginFailed = false;
        state.loginSuccess = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginFailed = false;
        state.user = action.payload.user;
        state.loginRequest = false;
        state.loginSuccess = true;
      })
      .addCase(login.rejected, (state) => {
        state.loginFailed = true;
        state.loginRequest = false;
        state.loginSuccess = false;
      })
      .addCase(autoAuth.pending, (state) => {
        state.loginRequest = true;
        state.loginFailed = false;
        state.loginSuccess = false;
      })
      .addCase(autoAuth.fulfilled, (state, action) => {
        state.loginFailed = false;
        state.user = action.payload.user;
        state.loginRequest = false;
        state.loginSuccess = true;
      })
      .addCase(autoAuth.rejected, (state) => {
        state.loginFailed = true;
        state.loginRequest = false;
        state.loginSuccess = false;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.updateUserPasswordRequest = true;
        state.updateUserPasswordFailed = false;
        state.updateUserPasswordSuccess = false;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.updateUserPasswordRequest = false;
        state.updateUserPasswordFailed = false;
        state.updateUserPasswordSuccess = true;
      })
      .addCase(updateUserPassword.rejected, (state) => {
        state.updateUserPasswordRequest = false;
        state.updateUserPasswordFailed = true;
        state.updateUserPasswordSuccess = false;
      })
      .addCase(deleteUserAccount.pending, (state) => {
        state.deleteUserRequest = true;
        state.deleteUserFailed = false;
        state.deleteUserSuccess = false;
      })
      .addCase(deleteUserAccount.fulfilled, (state) => {
        state.user = null;
        state.registerRequest = false;
        state.registerFailed = false;
        state.registerSuccess = false;
        state.loginRequest = false;
        state.loginFailed = false;
        state.loginSuccess = false;
        state.updateUserPasswordRequest = false;
        state.updateUserPasswordFailed = false;
        state.updateUserPasswordSuccess = false;
        state.deleteUserRequest = false;
        state.deleteUserFailed = false;
        state.deleteUserSuccess = true;
      })
      .addCase(deleteUserAccount.rejected, (state) => {
        state.deleteUserRequest = false;
        state.deleteUserFailed = true;
        state.deleteUserSuccess = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordRequest = true;
        state.forgotPasswordFailed = false;
        state.forgotPasswordSuccess = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPasswordRequest = false;
        state.forgotPasswordFailed = false;
        state.forgotPasswordSuccess = true;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPasswordRequest = false;
        state.forgotPasswordFailed = true;
        state.forgotPasswordSuccess = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordRequest = true;
        state.resetPasswordFailed = false;
        state.resetPasswordSuccess = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordRequest = false;
        state.resetPasswordFailed = false;
        state.resetPasswordSuccess = true;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordRequest = false;
        state.resetPasswordFailed = true;
        state.resetPasswordSuccess = false;
      })
  },
});

export const {
  registerRequest,
  registerSuccess,
  registerFailed,
  logOut,
  loginRequest,
  loginFailed,
  loginSuccess,
  updateUserPasswordRequest,
  updateUserPasswordFailed,
  updateUserPasswordSuccess,
  deleteUserRequest,
  deleteUserFailed,
  deleteUserSuccess,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  resetPasswordRequest,
  resetPasswordFailed,
  resetPasswordSuccess,
  confirmRegisterRequest,
  confirmRegisterFailed,
  confirmRegisterSuccess,
} = authSlice.actions;

export default authSlice.reducer;
