import { useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import { TValodatorPassword } from '../../utils/types';
import styles from './PasswordValidator.module.css';

const PasswordValidator = (passwordValue: TValodatorPassword) => {
  const { password } = passwordValue;

  const language = useAppSelector(state => state.languageSelectReducer.language)

  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const isValidLength = password.length >= 12;

  return (
    <ul className={styles.list}>
      <li className={`${styles.text} ${hasLowerCase && styles.text_true}`}>{language === Language.Russian ? 'Cодержит строчные буквы'
        : 'Contains lowercase letters'}</li>
      <li className={`${styles.text} ${hasUpperCase && styles.text_true}`}>{language === Language.Russian ? 'Cодержит заглавные буквы'
        : 'Contains uppercase letters'}</li>
      <li className={`${styles.text} ${hasNumbers && styles.text_true}`}>{language === Language.Russian ? 'Cодержит цифры'
        : 'Contains numbers'}</li>
      <li className={`${styles.text} ${hasSpecialChars && styles.text_true}`}>{language === Language.Russian ? 'Cодержит символы'
        : 'Contains special characters'}</li>
      <li className={`${styles.text} ${isValidLength && styles.text_true}`}>{language === Language.Russian ? 'Длина более 12 символов'
        : 'Length is greater than 12 characters'}</li>
    </ul>
  );
};

export default PasswordValidator;
