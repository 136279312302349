import { useEffect, useState } from 'react';
import styles from './Timer.module.css';
import { useAppDispatch, useAppSelector } from '../..';
import { decrementTime, resetTimer, startTimer } from '../../redux/timerSlice';
import { RouteParams } from '../../utils/types';
import { useParams } from 'react-router-dom';

const Timer = () => {
  const dispatch = useAppDispatch();
  const [timerColor, setTimerColor] = useState("var(--green-color)");
  const timeLeft = useAppSelector((state) => state.timerReducer.timeLeft);
  const answeredQuestionsQty = useAppSelector(state => state.testSelectReducer.answeredQuestionsQty);
  const totalQuestionsQty = useAppSelector(state => state.testSelectReducer.totalQuestionsQty);
  const { testId } = useParams<RouteParams>();

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    const decrement = () => {
      if (answeredQuestionsQty === totalQuestionsQty) {
        clearTimeout(timerId);
        return;
      }
      dispatch(decrementTime());
      if (timeLeft > 1000) {
        timerId = setTimeout(decrement, 1000);
      }
    };

    timerId = setTimeout(decrement, 1000);

    return () => clearTimeout(timerId);
  }, [dispatch, timeLeft, answeredQuestionsQty, totalQuestionsQty]);

  useEffect(() => {
    dispatch(resetTimer(totalQuestionsQty));
    dispatch(startTimer(totalQuestionsQty));
  }, [testId, dispatch, totalQuestionsQty]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    const remainingQuestions = totalQuestionsQty - answeredQuestionsQty;
    const remainingSeconds = timeLeft / 1000;
    const remainingMinutes = Math.ceil(timeLeft / 60000);

    if (remainingQuestions > 0) {
      const secondsPerQuestion = Math.ceil(remainingSeconds / remainingQuestions);

      if (remainingQuestions > remainingMinutes) {
        if (secondsPerQuestion < 30) {
          setTimerColor("var(--pink-color)");
        } else if (secondsPerQuestion < 50) {
          setTimerColor("yellow");
        }
      } else {
        setTimerColor("var(--green-color)");
      }
    } else {
      setTimerColor("var(--green-color)");
    }
  }, [answeredQuestionsQty, totalQuestionsQty, timeLeft]);

  return (
    <p className={styles.timer} style={{ color: timerColor }}>{formatTime(timeLeft)}</p>
  );
};

export default Timer;
