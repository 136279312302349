import { Link } from 'react-router-dom';
import styles from "./NotFound.module.css";
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { useAppSelector } from '../..';
import { Language } from '../../utils/constants';

export const NotFound = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);

  return (
    <div className={styles.conatiner}>
      <h1 className={styles.title}>{language === Language.English
        ? 'Unfortunately, this page does not exist' : 'К сожалению, такая страница не\u00A0существует'}</h1>
      <Link className={styles.link} to="/sections">
        <p className={styles.text}>{language === Language.English ? 'Return to the main page' : 'Вернуться на главную'}</p>
      </Link>
      <ToggleButton />
    </div>
  );
};

export default NotFound;
