import { configureStore } from "@reduxjs/toolkit";
import languageSelectReducer from "./languageSelectSlice";
import sectionSelectReducer from "./sectionSelectSlice";
import testSelectReducer from "./testSelectSlice";
import timerReducer from "./timerSlice";
import authReducer from "./auth/authSlice";
import difficultQuestionsReducer from "./difficultQuestionsSlice";

const store = configureStore({
  reducer: {
    authReducer,
    languageSelectReducer,
    sectionSelectReducer,
    testSelectReducer,
    timerReducer,
    difficultQuestionsReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
