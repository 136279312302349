import { Link, useNavigate } from 'react-router-dom';
import styles from './About.module.css';
import logo from '../../images/cdlMap_logo_black_background.svg';
import { useEffect, useRef, useState } from 'react';
import banner from '../../images/banner.webp';
import { CloseIcon } from '../../ui/icons';
import { Button } from '../../components/button/ButtonComponent';
import { Preloader } from '../../components/preloader/Preloader';
import CarouselComponent from '../../components/сarousel/CarouselComponent';
import FAQComponent from '../../components/faq/FAQComponent';
import instagramIcon from '../../images/instagram.svg';
import firstActionImage from '../../images/action1.png';
import secondActionImage from '../../images/action2.png';
import thirdActionImage from '../../images/action3.png';
import fourthActionImage from '../../images/action4.png';
import fifthActionImage from '../../images/action5.png';
import sixthActionImage from '../../images/action6.png';
import { useAppDispatch } from '../..';
import { setLanguage } from '../../redux/languageSelectSlice';
import { Language } from '../../utils/constants';

const About = () => {
  const contactsRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const howDoesItWorkRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleNavigate = (path: string) => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 700);
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const carouselItems = [
    {
      text: 'Зарегистируйтесь и подтвердите электронную почту',
      tag: '1 шаг',
      pic: firstActionImage,
      step: 1
    },
    {
      text: 'Выберите секцию',
      tag: ' 2 шаг',
      pic: secondActionImage,
      step: 2
    },
    {
      text: 'Выберите тест',
      tag: ' 3 шаг',
      pic: thirdActionImage,
      step: 3
    },
    {
      text: 'Ответьте на все вопросы теста',
      tag: ' 4 шаг',
      pic: fourthActionImage,
      step: 4
    },
    {
      text: 'Посмотрите количество верных и неверных ответов и время, за которое Вы прошли тест',
      tag: ' 5 шаг',
      pic: fifthActionImage,
      step: 5
    },
    {
      text: 'Если Вы ответили неверно, то вопрос попадет в сложные вопросы',
      tag: ' 6 шаг',
      pic: sixthActionImage,
      step: 6
    }
  ];

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLanguage(Language.Russian));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  return (
    <main className={styles.main}>
      {isLoading && <Preloader />}
      <header className={styles.header}>
        <Link to={"/"}>
          <img className={styles.logo} src={logo} alt='СDL Map лого' />
        </Link>
        <nav className={`${styles.menuHeader}`}>
          <ul className={styles.menuListHeader}>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(aboutRef)}>
              О CDL Map
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(howDoesItWorkRef)}>
              Как это работает
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(faqRef)}>
              FAQ
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(contactsRef)}>
              Контакты
            </li>
          </ul>
        </nav>
        <div>
          <ul className={styles.menuAdditionalListHeader}>
            <li className={styles.menuAdditionalItemHeader}>
              <Link to="/signin">Вход</Link>
            </li>
            <p className={styles.paragraph}>и</p>
            <li className={styles.menuAdditionalItemHeader}>
              <Link to="/signup">Регистрация</Link>
            </li>
          </ul>
        </div>
        <div aria-label="Выбор языка">
          <ul className={styles.menuLanguageListHeader}>
            <li className={styles.languageHeaderItem}>
              <Link to="/" aria-label="Русский">Ru</Link>
            </li>
            <p className={styles.paragraph}>/</p>
            <li className={styles.languageHeaderItem}>
              <Link to="/eng" aria-label="English">En</Link>
            </li>
          </ul>
        </div>
        <button className={styles.burgerButton} onClick={toggleMenu}>
          <div className={styles.burger}></div>
        </button>
        <div className={`${styles.dropdownMenu} ${isMenuOpen ? styles.menuOpen : ''}`}>
          <button className={styles.closeButton} onClick={toggleMenu}>
            <div className={styles.closeIcon}></div>
            <CloseIcon />
          </button>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(aboutRef);
            setIsMenuOpen(false);
          }}>
            О CDL Map
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(howDoesItWorkRef);
            setIsMenuOpen(false);
          }}>
            Как это работает
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(faqRef);
            setIsMenuOpen(false);
          }}>
            FAQ
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(contactsRef);
            setIsMenuOpen(false);
          }}>
            Контакты
          </div>
          <ul className={styles.dropdownMenuAuthList}>
            <li className={styles.dropdownMenuAuthListItem}>
              <Link to="/signin">Вход</Link>
            </li>
            <li className={styles.dropdownMenuAuthListItem}>
              <Link to="/signup">Регистрация</Link>
            </li>
          </ul>
          <div aria-label="Выбор языка">
            <ul className={styles.burgerLanguageListHeader}>
              <li className={styles.languageHeaderItem}>
                <Link to="/" aria-label="Русский">Ru</Link>
              </li>
              /
              <li className={styles.languageHeaderItem}>
                <Link to="/eng" aria-label="English">En</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <section className={styles.bannerSection}>
        <img className={styles.bannerImage} src={banner} alt='СDL Map баннер' />
        <div className={styles.bannerTextContainer}>
          <h1 className={styles.mainTitle}>Начни путь к&nbsp;<span className={styles.orangeText}>CDL</span> прямо сейчас</h1>
          <p className={styles.mainSubTitle}>Сервис для <span className={styles.orangeText}>бесплатной подготовки</span> к&nbsp;сдача экзамена для&nbsp;получения CDL&nbsp;в&nbsp;США в&nbsp;шатате Калифорния</p>
          <Button onClick={() => handleNavigate('/signup')} text='Зарегистироваться' buttonSize='big' type='submit' />
        </div>
      </section>
      <section className={styles.aboutSection} ref={aboutRef}>
        <div className={styles.sectionContainer}>
          <h2 className={styles.sectionTitle}>О CDL Map</h2>
          <p className={styles.sectionSubTitle}>Для cдачи теоретического экзамена на&nbsp;получение CDL нужно очень хорошо подготовиться.
            Данный сервис создан для того, чтобы&nbsp;Вы могли в&nbsp;удобной тестовой форме потренироваться в&nbsp;ответах на&nbsp;вопросы.</p>
        </div>
        <ul className={styles.aboutSectionList}>
          <li className={styles.aboutSectionItem}>
            <p>
              Вопросы <span className={styles.orangeText}>на&nbsp;русском и&nbsp;английском языках</span> с&nbsp;возможностью быстрого переключения
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Для регистрации <span className={styles.orangeText}>достаточно только электронной почты</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Можно проходить <span className={styles.orangeText}>тесты на&nbsp;смартфоне или компьютере</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Краткая сводка после</span> прохождения <span className={styles.orangeText}>теста</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Возможность прохождения <span className={styles.orangeText}>случайного теста</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Абсолютно <span className={styles.orangeText}>бесплатный сервис</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Секция сложных вопросов,</span> которые вызывают трудности именно у&nbsp;вас
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Составлено <span className={styles.orangeText}>на&nbsp;основе</span> информации
              из&nbsp;<span className={styles.orangeText}>официального руководства</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Пояснения к&nbsp;ответам</span> для лучшего понимания
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Таймер</span> в&nbsp;каждом тесте
            </p>
          </li>
        </ul>
      </section>
      <section ref={howDoesItWorkRef} className={styles.howDoesItWorkSection}>
        <h2 className={`${styles.sectionTitle} ${styles.blackText}`}>Как это работает</h2>
        <ul className={styles.actionsList}>
          <CarouselComponent items={carouselItems} />
        </ul>
      </section>
      <section className={styles.faqSection} ref={faqRef}>
        <h2 className={styles.sectionTitle}>Часто задаваемые вопросы (FAQ)</h2>
        <ul className={styles.faqSectionList}>
          <FAQComponent
            question='Могу ли изменить свой пароль в личном кабинете?'
            answer='Да. Вы можете изменить пароль в личном кабинете приложения. Если же Вы забыли пароль и не можете войти в аккаунт,
            то у вас есть возможность сделать восстановление пароля.' />
          <FAQComponent
            question='Это официальные тесты DMV?'
            answer='Данные тесты составлены на основании официального руководства, которое можно скачать на сайте DMV.
            На экзамене у Вас могу попасться похоживе вопросы с иными формулировками. Главное понять суть и  теорию, тогда иная
            формулировка не будет Вас пугать.' />
          <FAQComponent
            question='Этот сервис бесплатный?'
            answer='Да. Данный сервис предоставляет бесплатный доступ ко всем тестам.' />
          <FAQComponent
            question='Могу ли я менять язык во время прохождения теста?'
            answer='Да, можете.' />
          <FAQComponent
            question='Есть ли тесты для других штатов?'
            answer='Пока тесты есть только для штата Калифорния. В будущем возможно появление тестов для других штатов.' />
        </ul>
      </section>
      <section className={styles.contactsSection} ref={contactsRef}>
        <h2 className={styles.sectionTitle}>Контакты</h2>
        <p className={styles.sectionSubTitle}>По любым вопросам можете обращаться на наш электронный адрес:</p>
        <p className={styles.contactsEmail}>support@cdlmap.com</p>
      </section>
      <footer className={styles.footer}>
        <ul className={styles.menuListFooter}>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(aboutRef)}>О CDL Map</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(howDoesItWorkRef)}>Как это работает</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(faqRef)}>FAQ</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(contactsRef)}>Контакты</li>
          <li className={styles.menuListFooterItem}>
            <Link to="/signup">Регистрация</Link>
          </li>
          <li className={styles.menuListFooterItem}>
            <Link to="/signin">Вход</Link>
          </li>
        </ul>
        <div className={styles.socialInfo}>
          <a href="https://www.instagram.com/cdlmap/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a className={styles.emailTextFooter} href="mailto:support@cdlmap.com">support@cdlmap.com</a>
        </div>
        <div className={styles.techText}>
          <p className={styles.copyright}>© 2024 CDL  Map</p>
          <Link to="/service-rules">Правила сервиса</Link>
        </div>
      </footer>
    </main>
  );
}

export default About;
