import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDifficultQuestions, getDifficultQuestionsQty } from "../services/api";
import { IDifficultQuestions } from "../utils/types";
import { RootState } from "./store";

export const getDifficultQuestionsQtyThunk = createAsyncThunk<number, void, { rejectValue: { message: string, statusCode: number } }>(
  'difficultQuestion/getDifficultQuestionsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getDifficultQuestionsQty();
      return response.difficultQuestionsQty;
    } catch (err: any) {
      console.error("Error in Thunk:", err);
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const fetchDifficultQuestionsThunk = createAsyncThunk<IDifficultQuestions, void, { rejectValue: { message: string, statusCode: number }, state: RootState }>(
  'difficultQuestion/fetchDifficultQuestions',
  async (_, { getState, rejectWithValue }) => {
    const userStatus = getState().authReducer.user?.status.type;

    if (userStatus === 'basic') {
      return rejectWithValue({ message: 'Access denied for basic users', statusCode: 403 });
    }

    try {
      const response = await getDifficultQuestions();
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);
