import { createAsyncThunk } from "@reduxjs/toolkit";
import { confirmRegistryByVarifyEmail, createUser, deleteAccount, forgetPasswordServerRequest, getUserRequest, logInItoAccount, resetPasswordOnServer, updateUserPasswordAtServer } from "../../services/api";
import { RegisterArgs, TDefaultResponse, TUser } from "../../utils/types";

export const register = createAsyncThunk<TDefaultResponse, RegisterArgs, { rejectValue: { message: string, statusCode: number } }>(
  'auth/register',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await createUser(email, password);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const confirmRegister = createAsyncThunk<TUser, { token: string }, { rejectValue: { message: string, statusCode: number } }>(
  'auth/confirmRegister',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await confirmRegistryByVarifyEmail(token);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const login = createAsyncThunk<TUser, RegisterArgs, { rejectValue: { message: string, statusCode: number } }>(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await logInItoAccount(email, password);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const autoAuth = createAsyncThunk<TUser, void, { rejectValue: { message: string, statusCode: number } }>(
  'auth/autoAuth',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserRequest();
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const updateUserPassword = createAsyncThunk<TDefaultResponse, { password: string }, { rejectValue: { message: string, statusCode: number } }>(
  'auth/updateUserPassword',
  async (password, { rejectWithValue }) => {
    try {
      const response = await updateUserPasswordAtServer(password.password);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const deleteUserAccount = createAsyncThunk<TDefaultResponse, void, { rejectValue: { message: string, statusCode: number } }>(
  'auth/deleteUserAccount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await deleteAccount();
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const forgotPassword = createAsyncThunk<TDefaultResponse, { email: string }, { rejectValue: { message: string, statusCode: number } }>(
  'auth/forgetPassword ',
  async (email, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordServerRequest(email.email);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

export const resetPassword = createAsyncThunk<TDefaultResponse, { password: string, token: string }, { rejectValue: { message: string, statusCode: number } }>(
  'auth/resetPassword ',
  async ({ password, token }, { rejectWithValue }) => {
    try {
      const response = await resetPasswordOnServer(password, token);
      return response;
    } catch (err: any) {
      const statusCode = err.statusCode ? err.statusCode : 500;
      const message = err.response && err.response.data.message ? err.response.data.message : 'An unknown error occurred';
      return rejectWithValue({ message, statusCode });
    }
  }
);

