import { useEffect, useRef, useState } from 'react';
import styles from './ForgotPassword.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../components/input/InputComponent';
import { Button } from '../../components/button/ButtonComponent';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { isValidEmail } from '../../utils/functions';
import { Preloader } from '../../components/preloader/Preloader';
import { forgotPassword } from '../../redux/auth/authThunks';
import { Modal } from '../../components/modal/Modal';
import NotificationMessage from '../../components/notification-messsage/NotificationMessage';

export const ForgotPassword = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [emailValue, setEmailValue] = useState<string>('');
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lastErrorType, setLastErrorType] = useState<'invalid' | 'server' | 'exceededAttempts' | null>(null);
  const forgotPasswordRequest = useAppSelector(store => store.authReducer.forgotPasswordRequest);
  const [showModal, setShowModal] = useState(false);

  const onChangeEmail = (e: { target: { value: string }; }) => {
    setEmailValue(e.target.value);
  };

  const sentForgotPassword = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const actionResult = await dispatch(forgotPassword({ email: emailValue }));

    if (actionResult.meta.requestStatus === 'fulfilled') {
      setError(false);
      setErrorMessage('');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/signin');
      }, 5000);
    }
    else if (actionResult.meta.requestStatus === 'rejected') {
      const errorInfo = actionResult.payload as { message: string, statusCode: number };;
      setError(true);

      if (errorInfo.statusCode === 401 || errorInfo.statusCode === 404) {
        setLastErrorType('invalid');
      } else if (errorInfo.statusCode === 429) {
        setLastErrorType('exceededAttempts');
      } else {
        setLastErrorType('server');
      }
    } else {
      setError(false);
      setLastErrorType(null);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (lastErrorType) {
      let message = '';
      switch (lastErrorType) {
        case 'invalid':
          message = language === Language.English ? 'Error in entering email' : 'Ошибка ввода e-mail';
          break;
        case 'server':
          message = language === Language.English ? 'Server error, please try again later' : 'Ошибка сервера, попробуйте снова позже';
          break;
        case 'exceededAttempts':
          message = language === Language.English ? 'You have exceeded the number of password reset attempts. Please try again later.' : 'Вы исчерпали количество попыток сброса пароля. Пожалуйста, попробуйте позже.';
          break;
      }
      setErrorMessage(message);
    }
  }, [language, lastErrorType, error]);


  if (forgotPasswordRequest) {
    return <Preloader />;
  }

  return (
    <div className={styles.page}>
      {showModal &&
        (<Modal close={closeModal}>
          <NotificationMessage
            title={language === Language.English ? 'Check Your Email' : 'Проверьте Вашу почту'}
            text={language === Language.English ? 'A link to reset your password has been sent to your email address. The link is valid for 1 hour. If the link does not arrive soon, please check your Spam folder.'
              : 'На ваш электронный адрес отправлена ссылка для сброса пароля. Ссылка действительна в течение 1 часа. Если ссылка долго не приходит, проверьте папку «Спам».'} />
        </Modal>)}
      <div className={styles.mainContent}>
        <h2 className={styles.title}>{language === Language.English ? 'Password recovery' : 'Восстановление пароля'}</h2>
        <form className={styles.form} onSubmit={sentForgotPassword}>
          <div className={styles.inputCont}>
            <Input
              type={'email'}
              placeholder={language === Language.English ? 'Enter your email:' : 'Укажите e-mail:'}
              onChange={onChangeEmail}
              value={emailValue}
              name={'email'}
              error={error}
              ref={inputRef}
              errorText={errorMessage}
              size={'default'}
            />
          </div>
          <Button
            type="submit"
            text={language === Language.English ? 'Recover' : 'Восстановить'}
            disabled={(emailValue === '' || !isValidEmail(emailValue)) ? true : false}
            buttonSize='big'
          />
        </form>
        <div>
          <p className={styles.text}>{language === Language.English ? 'Remember your password?' : 'Вспомнили пароль?'}</p>
          <Link to="/signin" className={styles.link}>
            {language === Language.English ? 'Sign In' : 'Войти'}
          </Link>
        </div>
      </div>
      <div className={styles.toggle}>
        <ToggleButton />
      </div>
    </div>
  );
};

export default ForgotPassword;
