import { useState } from 'react';
import styles from './FAQComponent.module.css';
import { ArrowAccordion } from '../../ui/icons';

const FAQComponent: React.FC<{ question: string; answer: string; }> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <li>
      <div className={styles.questionCont}>
        <button className={`${styles.accordionArrow} ${isOpen ? styles.rotate : ''}`} onClick={toggleOpen}>
          <ArrowAccordion />
        </button>
        <p className={styles.questionText}>{question}</p>
      </div>
      <div className={`${styles.answerContainer} ${isOpen ? styles.active : ''}`}>
        <p className={styles.answerText}>{answer}</p>
      </div>
    </li>
  );
};

export default FAQComponent;
