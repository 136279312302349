import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITestItem } from "../utils/types";

const initialState = {
  testNumber: 0,
  questionsRange: {
    start: 0,
    end: 0
  },
  id: '',
  testName: {
    nameEng: '',
    nameRus: '',
  },
  answeredQuestionsQty: 0,
  totalQuestionsQty: 0,
};

const testSelectSlice = createSlice({
  name: 'testSelect',
  initialState,
  reducers: {
    setTest(state, action: PayloadAction<ITestItem>) {
      state.testNumber = action.payload.testNumber;
      state.questionsRange.start = action.payload.questionsRange.start;
      state.questionsRange.end = action.payload.questionsRange.end;
      state.id = action.payload._id!;
      state.testName = action.payload.testName!;
      state.totalQuestionsQty = action.payload.questionsRange.end - action.payload.questionsRange.start + 1;
    },
    updateAnsweredQuestions(state, action: PayloadAction<number>) {
      state.answeredQuestionsQty = action.payload;
    },
    resetTest(state) {
      state.testNumber = initialState.testNumber;
      state.questionsRange.start = initialState.questionsRange.start;
      state.questionsRange.end = initialState.questionsRange.end;
      state.id = initialState.id;
      state.answeredQuestionsQty = initialState.answeredQuestionsQty;
      state.totalQuestionsQty = initialState.totalQuestionsQty;
      state.testName = initialState.testName!;
    },
    resetAnsweredQuestions(state) {
      state.answeredQuestionsQty = 0;
    }
  }
});

export const { setTest, resetTest, updateAnsweredQuestions, resetAnsweredQuestions } = testSelectSlice.actions;

export default testSelectSlice.reducer;
