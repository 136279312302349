import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../components/input/InputComponent';
import { Language } from '../../utils/constants';
import styles from './SignIn.module.css';
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { useAppDispatch, useAppSelector } from '../..';
import { Button } from '../../components/button/ButtonComponent';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { login } from '../../redux/auth/authThunks';
import { TUser } from '../../utils/types';
import Cookies from 'js-cookie';
import { getDifficultQuestionsQtyThunk } from '../../redux/difficultQuestionsThunks';

export const SignIn = () => {
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const inputRefEmail = useRef<HTMLInputElement>(null);
  const inputRefPassword = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password');
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [isLoading, setIsLoading] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const loginFailed = useAppSelector(state => state.authReducer.loginFailed);
  const [lastErrorType, setLastErrorType] = useState<'invalid' | 'server' | null>(null);
  const user = useAppSelector(store => store.authReducer.user);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onChangeEmail = (e: { target: { value: string }; }) => {
    setEmailValue(e.target.value);

    if (loginFailed) {
      setLastErrorType(null);
      setError(false);
      setErrorMessage('');
    }
  };

  const onChangePassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setPasswordValue(e.target.value);
    if (loginFailed) {
      setErrorPassword(false);
      setErrorMessagePassword('');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };


  const determineIcon = () => {
    return passwordInputType === 'password' ? 'ShowIcon' : 'HideIcon';
  };

  const loginFunction = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    setIsLoading(true);

    const actionResult = await dispatch(login({ email: emailValue, password: passwordValue }));

    setIsLoading(false);

    if (actionResult.meta.requestStatus === 'fulfilled') {
      const response = actionResult.payload as TUser;
      if (response && response.accessToken && response.refreshToken) {
        Cookies.set('accessToken', response.accessToken, { expires: 1 / 24 });
        localStorage.setItem('refreshToken', response.refreshToken);
        dispatch(getDifficultQuestionsQtyThunk());
        setError(false);
        setErrorMessage('');
      }
    } else if (actionResult.meta.requestStatus === 'rejected') {
      const errorInfo = actionResult.payload as { message: string, statusCode: number };
      setError(true);

      if (errorInfo.statusCode === 401) {
        setLastErrorType('invalid');
      } else {
        setLastErrorType('server');
      }
    } else {
      setError(false);
      setLastErrorType(null);
    }
  };

  useEffect(() => {
    if (lastErrorType) {
      let message = '';
      switch (lastErrorType) {
        case 'invalid':
          message = language === Language.English ? 'Error in entering email or password' : 'Ошибка ввода e-mail или пароля';
          break;
        case 'server':
          message = language === Language.English ? 'Server error, please try again later' : 'Ошибка сервера, попробуйте снова позже';
          break;
      }
      setErrorMessage(message);
    }
  }, [language, lastErrorType, error]);

  useEffect(() => {
    if (user) {
      navigate('/sections');
    }
  }, [user, navigate]);

  return (
    <div className={styles.page}>
      <div className={styles.mainContent}>
        <h2 className={styles.title}>{language === Language.English ? 'Sign in' : 'Вход'}</h2>
        <form className={styles.form} onSubmit={loginFunction}>
          <fieldset className={styles.inputCont}>
            <Input
              type={'email'}
              placeholder={'E-mail:'}
              onChange={onChangeEmail}
              value={emailValue}
              name={'email'}
              error={error}
              errorText={errorMessage}
              ref={inputRefEmail}
              size={'default'}
            />
            <Input
              type={passwordInputType}
              placeholder={language === Language.English ? 'Password:' : 'Пароль:'}
              onChange={onChangePassword}
              value={passwordValue}
              name={'password'}
              error={errorPassword}
              errorText={errorMessagePassword}
              ref={inputRefPassword}
              icon={determineIcon()}
              onIconClick={togglePasswordVisibility}
              size={'default'}
            />
          </fieldset>
          <Button
            disabled={(emailValue === '' || passwordValue === '') ? true : false}
            text={language === Language.English ? 'Sign in' : 'Войти'}
            type='submit'
            buttonSize='big'
            isLoader={isLoading}
          />
        </form>
        <div>
          <p className={styles.text}>{language === Language.English ? 'Not registered yet?' : 'Еще не зарегистрированы?'}</p>
          <Link to="/signup" className={styles.link}>
            {language === Language.English ? 'Sign up' : 'Зарегистрироваться'}
          </Link>
        </div>
        <div>
          <p className={styles.text}>{language === Language.English ? 'Forgot password?' : 'Забыли пароль?'}</p>
          <Link to="/forgot-password" className={styles.smallTextlink}>
            {language === Language.English ? 'Reset password' : 'Восстановить пароль'}
          </Link>
        </div>
      </div>
      <div className={styles.toggle}>
        <ToggleButton />
      </div>
    </div>
  );
};

export default SignIn;
