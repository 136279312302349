import { SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './SignUp.module.css';
import { useAppDispatch, useAppSelector } from '../..';
import { register } from '../../redux/auth/authThunks';
import { Link, useNavigate } from "react-router-dom";
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { Language } from '../../utils/constants';
import { Button } from '../../components/button/ButtonComponent';
import { Input } from '../../components/input/InputComponent';
import { isValidEmail, validatePassword } from '../../utils/functions';
import PasswordValidator from '../../components/password-validator/PasswordValidator';
import { Modal } from '../../components/modal/Modal';
import NotificationMessage from '../../components/notification-messsage/NotificationMessage';

const SignUp = () => {
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const inputRefEmail = useRef<HTMLInputElement>(null);
  const inputRefPassword = useRef<HTMLInputElement>(null);
  const inputRefRepeatPassword = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const user = useAppSelector(store => store.authReducer.user);
  const registerFailed = useAppSelector(state => state.authReducer.registerFailed);
  const [isLoading, setIsLoading] = useState(false);
  const [lastErrorType, setLastErrorType] = useState<'conflict' | 'invalid' | 'server' | null>(null);
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password');
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsConsentChecked(e.target.checked);
  };

  const onChangeEmail = (e: { target: { value: string }; }) => {
    setEmailValue(e.target.value);

    if (registerFailed) {
      setLastErrorType(null);
      setError(false);
      setErrorMessage('');
    }

    if (!isValidEmail(e.target.value)) {
      setError(true);
      setErrorMessage(language === Language.English ? 'E-mail must contain @ and the domain part' : 'E-mail должен содержать @ и доменную часть');
    } else {
      if (!registerFailed) {
        setError(false);
        setErrorMessage('');
      }
    }
  };

  const onChangePassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setPasswordValue(e.target.value);
    setRepeatPasswordValue('');
    if (registerFailed) {
      setErrorPassword(false);
      setErrorMessagePassword('');
    }
  };

  const onChangeRepeatPassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setRepeatPasswordValue(e.target.value);
    if (registerFailed) {
      setErrorPassword(false);
      setErrorMessagePassword('');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };

  useEffect(() => {
    if (!validatePassword(passwordValue) && passwordValue !== '') {
      setErrorPassword(true);
      setErrorMessagePassword(language === Language.English ? 'Password must meet the requirements:' : 'Пароль должен соответствовтаь требованиям:');
    } else if (validatePassword(passwordValue) && repeatPasswordValue !== '' && passwordValue !== repeatPasswordValue) {
      setErrorPassword(true);
      setErrorMessagePassword(language === Language.English ? 'Passwords do not match' : 'Пароли не совпадают');
    } else {
      setErrorPassword(false);
      setErrorMessagePassword('');
    }
  }, [passwordValue, language, repeatPasswordValue]);

  useEffect(() => {
    if (!isValidEmail(emailValue) && emailValue !== '') {
      setError(true);
      setErrorMessage(language === Language.English ? 'E-mail must contain @ and the domain part' : 'E-mail должен содержать @ и доменную часть');
    } else if (!lastErrorType) {
      setError(false);
      setErrorMessage('');
    }
  }, [emailValue, language]);

  const determineIcon = () => {
    return passwordInputType === 'password' ? 'ShowIcon' : 'HideIcon';
  };

  const determineIconClickHandler = () => {
    return togglePasswordVisibility;
  };

  const userRegister = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    setIsLoading(true);

    const actionResult = await dispatch(register({ email: emailValue, password: passwordValue }));

    setIsLoading(false);

    if (actionResult.meta.requestStatus === 'fulfilled') {
      setShowModal(true);
      setTimeout(() => {
        navigate('/signin');
      }, 7000);
      setError(false);
      setErrorMessage('');

    } else if (actionResult.meta.requestStatus === 'rejected') {
      const errorInfo = actionResult.payload as { message: string, statusCode: number };
      setError(true);

      if (errorInfo.statusCode === 409) {
        setLastErrorType('conflict');
      } else if (errorInfo.statusCode === 400) {
        setLastErrorType('invalid');

      } else if (errorInfo.statusCode === 500) {
        setLastErrorType('server');
      }
    } else {
      setError(false);
      setLastErrorType(null);
    }
  };

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      navigate('/sections');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (lastErrorType) {
      let message = '';
      switch (lastErrorType) {
        case 'conflict':
          message = language === Language.English ? 'User already exists' : 'Пользователь уже существует';
          break;
        case 'invalid':
          message = language === Language.English ? 'Error in entering email or password' : 'Ошибка ввода e-mail или пароля';
          break;
        case 'server':
          message = language === Language.English ? 'Server error, please try again later' : 'Ошибка сервера, попробуйте снова позже';
          break;
      }
      setErrorMessage(message);
    }
  }, [language, lastErrorType, error]);

  return (
    <div className={styles.page}>
      {showModal &&
        (<Modal close={closeModal}>
          <NotificationMessage
            title={language === Language.English ? 'Registration Confirmation' : 'Подтверждение Регистрации'}
            text={language === Language.English ?
              'Registration link sent to your email, valid for 1 hour. After expiration, re-registration is required. If the link does not arrive soon, please check your Spam folder.'
              : 'Ссылка для завершения регистрации отправлена на ваш email. Она действительна 1 час. По истечении этого срока потребуется новая регистрация. Если ссылка долго не приходит, проверьте папку «Спам».'} />
        </Modal>)}
      <div className={styles.mainContent}>
        <h2 className={styles.title}>{language === Language.English ? 'Sign up' : 'Регистрация'}</h2>
        <form className={styles.form} onSubmit={userRegister}>
          <fieldset className={styles.inputCont}>
            <Input
              type={'email'}
              placeholder={'E-mail:'}
              onChange={onChangeEmail}
              value={emailValue}
              name={'email'}
              error={error}
              errorText={errorMessage}
              ref={inputRefEmail}
              size={'default'}
              icon={isValidEmail(emailValue) ? 'CheckMarkIcon' : null}
            />
            <Input
              type={passwordInputType}
              placeholder={language === Language.English ? 'Password:' : 'Пароль:'}
              onChange={onChangePassword}
              value={passwordValue}
              name={'password'}
              error={errorPassword}
              errorText={errorMessagePassword}
              ref={inputRefPassword}
              icon={determineIcon()}
              onIconClick={determineIconClickHandler()}
              size={'default'}
            />
            {validatePassword(passwordValue) &&
              <Input
                type={'password'}
                placeholder={language === Language.English ? 'Repeat password:' : 'Повторите пароль:'}
                onChange={onChangeRepeatPassword}
                value={repeatPasswordValue}
                name={'repeatPasswordValue'}
                error={errorPassword}
                errorText={errorMessagePassword}
                ref={inputRefRepeatPassword}
                size={'default'}
                onPaste={(e) => e.preventDefault()}
                icon={passwordValue === repeatPasswordValue ? 'CheckMarkIcon' : null}
              />}
            {!validatePassword(passwordValue) && passwordValue !== '' && <PasswordValidator password={passwordValue} />}
            {validatePassword(passwordValue) && passwordValue === repeatPasswordValue && <label className={styles.consentCheckbox}>
              <input type="checkbox" name="consent" onChange={handleConsentChange}/>
              {language === Language.Russian && <span className={styles.smallText}>Я соглашаюсь
                с <Link className={styles.specialLink} to="/service-rules">Правилами сервиса</Link></span>}
              {language === Language.English && <span className={styles.smallText}>I agree to the <Link className={styles.specialLink} to="/service-rules">Service Rules</Link></span>}
            </label>}
          </fieldset>
          {validatePassword(passwordValue) && passwordValue === repeatPasswordValue && <Button
            disabled={(emailValue === '' || passwordValue === '' || !isValidEmail(emailValue) || !isConsentChecked) ? true : false}
            text={language === Language.English ? 'Sign up' : 'Зарегистрироваться'}
            type='submit'
            buttonSize='big'
            isLoader={isLoading}
          />}
        </form>
        <div>
          <p className={styles.text}>{language === Language.English ? 'Already registered?' : 'Уже зарегистрированы?'}</p>
          <Link to="/signin" className={styles.link}>
            {language === Language.English ? 'Sign in' : 'Войти'}
          </Link>
        </div>
      </div>
      <div className={styles.toggle}>
        <ToggleButton />
      </div>
    </div>
  );
};

export default SignUp;
