import { TSliderIconPros } from "../../utils/types";

export const ArrowSlider = (props:  TSliderIconPros) => {
  const { className, style, onClick, image, isDisabled } = props;
  if (isDisabled) {
    return null;
  }
  return (
    <img
      src={image}
      alt='Стрелка'
      className={className}
      style={{ ...style, cursor: 'pointer' }}
      onClick={onClick}
    />
  );
};
