import { INotificationMessage } from '../../utils/types';
import styles from './NotificationMessage.module.css';

const NotificationMessage = ({ title, text }: INotificationMessage ) => {

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
    </>
  );
};

export default NotificationMessage;
