import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import { ISectionsList, ITestsList, MenuProps } from '../../utils/types';
import styles from './Menu.module.css';
import { LockIcon } from '../../ui/icons';

const Menu: React.FC<MenuProps> = ({ name, items, handleClick, totalQuestionsInSection, totalQuestionsInTest }) => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const userStatus = useAppSelector(state => state.authReducer.user?.status.type);

  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 300);
  const isTestList = (items: ISectionsList | ITestsList): items is ITestsList => 'tests' in items;

  const titleContent = name ?? (isTestList(items) ? (language === Language.English ? items.sectionNameEn : items.sectionNameRu) : '');

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={styles.menu}>
      <h2 className={styles.mainTitle}>{titleContent}</h2>
      <ul className={styles.list} style={{ height: containerHeight }}>
        {isTestList(items)
          ? items.tests.map((test) =>
            <li className={styles.title} key={test._id} onClick={() => handleClick(test)} >
              <div className={`${styles.container} ${userStatus === 'basic' && test.testNumber !== 1 && styles.lock} `}>
                {
                  !test.testName
                    ? (
                      language === Language.English
                        ? `Test ${test.testNumber}: Questions ${test.questionsRange.start}-${test.questionsRange.end}`
                        : `Тест ${test.testNumber}: Вопросы ${test.questionsRange.start}-${test.questionsRange.end}`
                    )
                    : (
                      language === Language.English
                        ? `${test.testName && test.testName.nameEng}: Questions ${test.questionsRange.start}-${test.questionsRange.end}`
                        : `${test.testName && test.testName.nameRus}: Вопросы ${test.questionsRange.start}-${test.questionsRange.end}`
                    )
                }
                {totalQuestionsInTest && <p className={`${styles.count}
                ${userStatus === 'basic' && test.testNumber !== 1 && styles.lockCount}`}>{`(${test.questionsCount})`}</p>}
                {userStatus === 'basic' && test.testNumber !== 1 &&
                  <div className={styles.icon}><LockIcon /></div>}
              </div>
            </li>) :
          items.sections.map((section) =>
            <li className={styles.title} key={section._id} onClick={() => handleClick(section)} >
              <div className={styles.container}>
                {language === Language.English ? section.sectionNameEn : section.sectionNameRu}
                {totalQuestionsInSection && <p className={styles.count}>{`(${section.totalQuestions})`}</p>}
              </div>
            </li>)}
      </ul>
    </nav>
  );
};

export default Menu;


