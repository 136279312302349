import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './ConfirmationEmailPage.module.css';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { useEffect, useState } from 'react';
import { confirmRegister } from '../../redux/auth/authThunks';
import Cookies from 'js-cookie';

const ConfirmationEmailPage = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [verificationSuccess, setVerificationSuccess] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;;

    if (token) {
      dispatch(confirmRegister({ token }))
        .unwrap()
        .then((response) => {
          Cookies.set('accessToken', response.accessToken, { expires: 1 / 24 });
          localStorage.setItem('refreshToken', response.refreshToken);
          setVerificationSuccess(true);

          timeoutId = setTimeout(() => {
            navigate('/sections');
          }, 5000);
        })
        .catch((error) => {
          console.error('Verification failed:', error);
          setVerificationSuccess(false);
        });
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [token, dispatch, navigate]);

  return (
    <>
      {!verificationSuccess ? (
        <div className={styles.conatiner}>
          <h1 className={styles.title}>{language === Language.English
            ? 'Unfortunately, the link has expired. Now you will be redirected to the main page of the application.'
            : 'К сожалению, срок действия ссылки истек. Необходимо пройти процесс регистрации заново'}</h1>
          <Link className={styles.link} to="/signup">
            <p className={styles.text}>{language === Language.English ? 'Return to the registration page' : 'Вернутся на страницу регистрации'}</p>
          </Link>
          <ToggleButton />
        </div>
      ) : (
        <div className={styles.conatiner}>
          <h1 className={styles.title}>{language === Language.English
            ? 'Congratulations! You have successfully registered. C.'
            : 'Поздравляем! Вы успешно зарегистрированы. Сейчас Вы будете перенеправлены на главную страницу приложения'}</h1>
          <Link className={styles.link} to="/sections">
            <p className={styles.text}>{language === Language.English ? 'Go to the main page' : 'На главную'}</p>
          </Link>
          <ToggleButton />
        </div>
      )}
    </>
  );
};

export default ConfirmationEmailPage;
