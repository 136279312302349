import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Menu from '../menu/Menu';
import ToggleButton from '../toggle-button/ToggleButton';
import styles from './TestList.module.css';
import { ITestItem, ITestsList, RouteParams } from '../../utils/types';
import { getTests } from '../../services/api';
import { useAppDispatch, useAppSelector } from '../..';
import { Preloader } from '../preloader/Preloader';
import { setTest } from '../../redux/testSelectSlice';

const TestList = () => {
  const [tests, setTests] = useState<ITestsList>();
  const { sectionId: urlSectionId } = useParams<RouteParams>();
  const userStatus = useAppSelector(state => state.authReducer.user?.status.type);
  const sectionId = useAppSelector(state => state.sectionSelectReducer.id) || urlSectionId;

  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onTestClick = (item: ITestItem) => {
    if (userStatus === 'basic' && item.testNumber !== 1) {
      return;
    }
    dispatch(setTest(item));
    navigate(`/sections/${item.sectionId}/${item._id}/questions`);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;;

    const fetchTests = async () => {
      try {
        const fetchedTests = await getTests(sectionId!);
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setTests(fetchedTests);
          setLoading(false);
        }, 600);
      } catch (error) {
        console.error("Error fetching sections:", error);
        setLoading(false);
      }
    };

    fetchTests();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sectionId]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      {tests && <Menu items={tests} handleClick={onTestClick} />}
      <ToggleButton />
    </div>
  );
};

export default TestList;
