import { useLocation, useParams } from 'react-router-dom';
import styles from './Layout.module.css';
import BackButton from '../back-button/BackButton';
import { LayoutProps } from '../../utils/types';
import BurgerMenu from '../burger-menu/BurgerMenu';
import { useEffect } from 'react';
import { useAppDispatch } from '../..';
import { resetSection } from '../../redux/sectionSelectSlice';
import { resetTest } from '../../redux/testSelectSlice';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { sectionId, testId } = useParams();

  useEffect(() => {
    const pathArray = location.pathname.split('/');
    if (!pathArray.includes('sections')) {
      dispatch(resetSection());
      dispatch(resetTest())
    }
  }, [sectionId, dispatch]);

  return (
    <>
      <header className={styles.header}>
        {location.pathname !== '/sections'
          ? <BackButton /> : <div className={styles.placeholder}></div>}
        <BurgerMenu />
      </header>
      <div>{children}</div>
    </>
  );
};

export default Layout;


