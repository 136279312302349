import previousArrow from '../../images/previousAarrow.svg';
import nextArrow from '../../images/nextArrow.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './CarouselComponent.module.css';
import { ArrowSlider } from '../../ui/icons';
import { useState } from 'react';

const CarouselComponent: React.FC<{
  items: {
    text: string,
    tag: string,
    pic: string,
    step: number
  }[]
}> = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <ArrowSlider image={previousArrow} isDisabled={currentSlide === 0} />,
    nextArrow: <ArrowSlider image={nextArrow} isDisabled={currentSlide === 3} />,
    afterChange: (current: number) => setCurrentSlide(current),

    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nextArrow: <ArrowSlider image={nextArrow} isDisabled={currentSlide === 4} />,
        }
      },
      {
        breakpoint: 1147,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <ArrowSlider image={nextArrow} isDisabled={currentSlide === 5} />,
        }
      },
    ]
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        {items && items.map((item) =>
          <div key={item.step}>
            <li className={styles.actionsListItem}>
              <div className={styles.actionsListItemTextContainer}>
                <p className={styles.actionOrder}>{item.step}</p>
                <p className={styles.actionText}>{item.text}</p>
              </div>
              <img className={styles.actionImage} src={item.pic} alt={item.tag} />
            </li>
          </div>
        )}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
