import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDifficultQuestionsThunk, getDifficultQuestionsQtyThunk } from "./difficultQuestionsThunks";
import { IDifficultQuestions, TCurrentAnsweredQuestion } from "../utils/types";

const initialState = {
  difficultQuestionsQty: 0,
  difficultQuestionsQtyRequest: false,
  difficultQuestionsQtyFailed: false,
  difficultQuestionsQtySuccess: false,
  currentAnsweredQuestionsArray: [] as Array<TCurrentAnsweredQuestion>,
  difficultQuestions: null as IDifficultQuestions | null,
  difficultQuestionsLoading: false,
  difficultQuestionsError: false,
};

const difficultQuestionsSlice = createSlice({
  name: 'difficultQuestion',
  initialState,
  reducers: {
    setDifficultQuestionsQty(state, action: PayloadAction<number>) {
      state.difficultQuestionsQty = action.payload;
    },
    resetDifficultQuestionsQty(state) {
      state.difficultQuestionsQty = initialState.difficultQuestionsQty;
    },
    addCurrentAnsweredQuestionsArray(state, action: PayloadAction<{ questionId?: string; isRight?: boolean }>) {
      state.currentAnsweredQuestionsArray.push(action.payload);
    },
    clearCurrentAnsweredQuestionsArray(state) {
      state.currentAnsweredQuestionsArray = initialState.currentAnsweredQuestionsArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDifficultQuestionsQtyThunk.pending, (state) => {
        state.difficultQuestionsQtyRequest = true;
        state.difficultQuestionsQtyFailed = false;
        state.difficultQuestionsQtySuccess = false;
      })
      .addCase(getDifficultQuestionsQtyThunk.fulfilled, (state, action) => {
        state.difficultQuestionsQtyRequest = false;
        state.difficultQuestionsQtySuccess = true;
        state.difficultQuestionsQty = action.payload;
      })
      .addCase(getDifficultQuestionsQtyThunk.rejected, (state) => {
        state.difficultQuestionsQtyRequest = false;
        state.difficultQuestionsQtyFailed = true;
      })
      .addCase(fetchDifficultQuestionsThunk.pending, (state) => {
        state.difficultQuestionsLoading = true;
        state.difficultQuestionsError = false;
      })
      .addCase(fetchDifficultQuestionsThunk.fulfilled, (state, action) => {
        state.difficultQuestionsLoading = false;
        state.difficultQuestions = action.payload;
        state.difficultQuestionsQtyFailed = false;
      })
      .addCase(fetchDifficultQuestionsThunk.rejected, (state) => {
        state.difficultQuestionsLoading = false;
        state.difficultQuestionsError = true;
      });
  },
});

export const {
  setDifficultQuestionsQty,
  resetDifficultQuestionsQty,
  addCurrentAnsweredQuestionsArray,
  clearCurrentAnsweredQuestionsArray
} = difficultQuestionsSlice.actions;

export default difficultQuestionsSlice.reducer;
