import { SetStateAction, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../..";
import { Input } from "../../components/input/InputComponent";
import ToggleButton from "../../components/toggle-button/ToggleButton";
import { EditIcon, CancelIcon } from "../../ui/icons";
import { Language } from "../../utils/constants";
import styles from "./UserProfile.module.css";
import { convertISODateToDateStrForRus, convertISODateToLongFormatForEng, validatePassword } from "../../utils/functions";
import PasswordValidator from "../../components/password-validator/PasswordValidator";
import { Button } from "../../components/button/ButtonComponent";
import { Modal } from "../../components/modal/Modal";
import NotificationMessage from "../../components/notification-messsage/NotificationMessage";
import { deleteUserAccount, updateUserPassword } from "../../redux/auth/authThunks";
import { Preloader } from "../../components/preloader/Preloader";
import { useNavigate } from "react-router-dom";

export const UserProfile = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const user = useAppSelector(state => state.authReducer.user);
  const [passwordValue, setPasswordValue] = useState<string>('yourPassword');
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 95);
  const inputRefPassword = useRef<HTMLInputElement>(null);
  const inputRefRepeatPassword = useRef<HTMLInputElement>(null);
  const [isInputDisabled, setInputDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpened, setModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastErrorType, setLastErrorType] = useState<'invalid' | 'server' | null>(null);
  const updateUserPasswordRequest = useAppSelector(state => state.authReducer.updateUserPasswordRequest);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const deleteUserAcountRequest = useAppSelector(state => state.authReducer.deleteUserRequest);
  const navigate = useNavigate();

  const onChangePassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setPasswordValue(e.target.value);
  };

  const onChangeRepeatPassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setRepeatPasswordValue(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setInputDisabled(false);
    setPasswordValue('');
    setTimeout(() => inputRefPassword.current?.focus(), 0);
    setRepeatPasswordValue('');
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setInputDisabled(true);
    setPasswordInputType('password');
    setPasswordValue('yourPassword');
    setRepeatPasswordValue('');
    setError(false);
    setErrorMessage('');
  };

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const savePassword = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const updatePasswordresult = await dispatch(updateUserPassword({ password: passwordValue }));

    if (updatePasswordresult.meta.requestStatus === 'fulfilled') {
      const response = updatePasswordresult.payload;
      if (response?.message === "Password is saved successfully") {
        setError(false);
        setErrorMessage('');
        setModalOpened(true);
        setIsEditing(false);
        setInputDisabled(true);
        setPasswordInputType('password');
        setPasswordValue('yourPassword');
        setRepeatPasswordValue('');
      }
    } else if (updatePasswordresult.meta.requestStatus === 'rejected') {
      const errorInfo = updatePasswordresult.payload as { message: string, statusCode: number };
      setError(true);

      if (errorInfo.statusCode === 400) {
        setLastErrorType('invalid');
      } else if (errorInfo.statusCode === 500) {
        setLastErrorType('server');
      }
    } else {
      setError(false);
      setLastErrorType(null);
    }
  };

  const handleDeleteButton = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteUserAccount = async (e: React.MouseEvent) => {
    e.preventDefault();

    const deleteUserAccountByUser = await dispatch(deleteUserAccount());

    if (deleteUserAccountByUser.meta.requestStatus === 'fulfilled') {
      const response = deleteUserAccountByUser.payload;
      if (response?.message === "User account deleted successfully") {
        setIsDeleteConfirmationModalOpen(false);
        navigate('/sections');
      }
    }
  };

  useEffect(() => {
    if (lastErrorType) {
      let message = '';
      switch (lastErrorType) {
        case 'invalid':
          message = language === Language.English ? 'Error in entering password' : 'Ошибка ввода пароля';
          break;
        case 'server':
          message = language === Language.English ? 'Server error, please try again later' : 'Ошибка сервера, попробуйте снова позже';
          break;
      }
      setErrorMessage(message);
    }
  }, [language, lastErrorType, error]);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 95);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (updateUserPasswordRequest || deleteUserAcountRequest) {
    return <Preloader />
  }

  return (
    <div className={styles.page} style={{ height: containerHeight }}>
      <div className={styles.topSection}>
        <h1 className={styles.title}>{language === Language.English ? 'Мy account' : 'Мой аккаунт'}</h1>
        <p className={styles.name}>{user?.email}</p>
        {isModalOpened && <Modal close={closeModal}>
          <NotificationMessage
            title={language === Language.English ? 'The password is saved' : 'Пароль сохранен'}
            text={language === Language.English ? 'Your password has been successfully changed.' : 'Ваш пароль был успешно изменен.'} />
        </Modal>}
        {isDeleteConfirmationModalOpen && <Modal close={closeDeleteConfirmationModal}>
          <NotificationMessage
            title={language === Language.English ? 'Are you sure?' : 'Вы уверены?'}
            text={language === Language.English ? 'After confirming the deletion, the account will be permanently deleted, and you will be redirected to the login page.'
              : 'После подтверждения удаления, аккаунт будет удален безвозвратно, и Вы будете перенаправлены на страницу входа.'} />
          <div className={styles.deleteButtonInModal}>
            <Button onClick={handleDeleteUserAccount}
              text={language === Language.English ? 'Yes' : 'Да'}
              buttonSize='small'
              type='submit'
            />
          </div>
        </Modal>}
        <form onSubmit={savePassword}>
          <fieldset className={styles.inputCont}>
            <Input
              type={passwordInputType}
              placeholder={language === Language.English ? 'Password:' : 'Пароль:'}
              onChange={onChangePassword}
              value={passwordValue}
              name={'password'}
              error={error}
              errorText={errorMessage}
              ref={inputRefPassword}
              size={'default'}
              icon={passwordValue && !isInputDisabled ? (passwordInputType === 'password' ? 'ShowIcon' : 'HideIcon') : null}
              onIconClick={togglePasswordVisibility}
              disabled={isInputDisabled}
              autoComplete="new-password"
            />
            <button type="button" className={styles.editButton}>
              {isEditing ? <CancelIcon onClick={handleCancelClick} /> : <EditIcon onClick={handleEditClick} />}
            </button>
            {validatePassword(passwordValue) &&
              <Input
                type={'password'}
                placeholder={language === Language.English ? 'Repeat password:' : 'Повторите пароль:'}
                onChange={onChangeRepeatPassword}
                value={repeatPasswordValue}
                name={'repeatPasswordValue'}
                ref={inputRefRepeatPassword}
                size={'default'}
                onPaste={(e) => e.preventDefault()}
                icon={passwordValue === repeatPasswordValue ? 'CheckMarkIcon' : null}
                autoComplete="new-password"
              />}
            {!validatePassword(passwordValue) && passwordValue !== 'yourPassword' &&
              <div className={styles.validationPart}>
                <h2 className={styles.validationPartTitle}>{language === Language.English ? 'Password requirements:' : 'Требования к паролю:'}</h2>
                <PasswordValidator password={passwordValue} />
              </div>}

            {validatePassword(passwordValue) && passwordValue !== 'yourPassword' &&
              <Button
                disabled={passwordValue !== repeatPasswordValue ? true : false}
                text={language === Language.English ? 'Save' : 'Cохранить'}
                type='submit'
                buttonSize='small'
              />
            }
          </fieldset>
        </form>
        {/* {user?.status.type === 'basic' && <p className={styles.status}>{language === Language.English ? 'basic status' : 'базовый статус'}</p>}
        {user?.status.type === 'premium' &&
          <p className={`${styles.status} ${styles.premium}`}>
            {language === Language.English ? 'premium status' : 'премиум статус'}
            <span className={styles.comment}>
              {language === Language.English
                ? `valid until ${convertISODateToLongFormatForEng(user.status.expiresAt)}`
                : `действует до ${convertISODateToDateStrForRus(user.status.expiresAt)}`}
            </span>
          </p>
        } */}
      </div>
      <div className={styles.toggleButton}>
        <ToggleButton />
      </div>
      <button className={styles.deleteButton} onClick={handleDeleteButton}>
        {language === Language.English ? 'delete account' : 'удалить аккаунт'}
      </button>
    </div>
  );
};


export default UserProfile;
