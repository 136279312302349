import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import styles from './Question.module.css';
import noIcon from '../../images/no.svg';
import yesIcon from '../../images/yes.svg';
import { CustomRadioButtonProps, IQuestionComponent, RouteParams } from '../../utils/types';
import ToggleButton from '../toggle-button/ToggleButton';
import Timer from '../timer/Timer';
import { addCurrentAnsweredQuestionsArray } from '../../redux/difficultQuestionsSlice';
import { useParams } from 'react-router-dom';
import { loadImage } from '../../utils/functions';

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({ checked, icon }) => (
  <div className={styles.customRadioButton}
    style={{
      width: '22px',
      height: '22px',
      backgroundImage: checked ? `url(${icon})` : 'none',
      backgroundSize: 'cover',
      border: '1px solid #ddd',
      borderRadius: '50%',
      display: 'inline-block',
      cursor: 'pointer',
      marginRight: '15px',
    }}
  />
);

const Question: React.FC<IQuestionComponent> = ({ question, questionIndex, onAnswer, onShowResults, onButtonClick, isDifficultQuestion, isModalOpen }) => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean | null>(null);
  const [correctAnswerId, setCorrectAnswerId] = useState<string | null>(null);
  const totalQurstionsInTest = useAppSelector(state => state.testSelectReducer.totalQuestionsQty);
  const { sectionId, testId } = useParams<RouteParams>();
  const difficultQuestionsSections = useAppSelector(state => state.difficultQuestionsReducer.difficultQuestions?.sections);
  const difficultQuestionsTests = difficultQuestionsSections && difficultQuestionsSections.find((section) => section._id === sectionId)?.tests;
  const difficultQuestionsTest = difficultQuestionsTests?.find((test) => test._id === testId);
  const totalQuestionsQty = isDifficultQuestion === true ? difficultQuestionsTest?.questionsCount : totalQurstionsInTest;
  const selectedSection = useAppSelector(state => state.sectionSelectReducer);
  const selectedTest = useAppSelector(state => state.testSelectReducer);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 95);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleAnswerChange = (answerId: string, isCorrect: boolean) => {
    if (selectedAnswer !== null) return;
    setSelectedAnswer(answerId);
    setIsAnswerCorrect(isCorrect);
    onAnswer(isCorrect);
    setIsDisabledButton(false);

    const correctAnswer = question.answers.find(answer => answer.isCorrect === true);

    if (!isCorrect) {
      if (correctAnswer && correctAnswer._id) {
        setCorrectAnswerId(correctAnswer._id);
      }
    }

    dispatch(addCurrentAnsweredQuestionsArray({ questionId: question._id, isRight: correctAnswer?._id === answerId ? true : false }));
  };

  const selectedAnswerComment = question.answers.find(answer =>
    (selectedAnswer === answer._id && isAnswerCorrect !== null && answer.commentEn !== '') ||
    (correctAnswerId === answer._id && selectedAnswer !== correctAnswerId)
  );

  useEffect(() => {
    setSelectedAnswer(null);
    setIsAnswerCorrect(null);
    setIsDisabledButton(true);
  }, [questionIndex]);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 95);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.wholeQuestionCont} style={{ height: containerHeight }}>
        <article className={styles.info}>
          <p className={styles.section}>{language === Language.English ? (`Section: ${selectedSection.sectionNameEn}`) : (`Секция: ${selectedSection.sectionNameRu}`)}</p>
          <div className={styles.container}>
            <p className={styles.test}>
              {
                !selectedTest.testName
                  ? (
                    language === Language.English
                      ? `Test ${selectedTest.testNumber}: Questions ${selectedTest.questionsRange.start}-${selectedTest.questionsRange.end}`
                      : `Тест ${selectedTest.testNumber}: Вопросы ${selectedTest.questionsRange.start}-${selectedTest.questionsRange.end}`
                  )
                  : (
                    language === Language.English
                      ? `${selectedTest.testName && selectedTest.testName.nameEng}: Questions ${selectedTest.questionsRange.start}-${selectedTest.questionsRange.end}`
                      : `${selectedTest.testName && selectedTest.testName.nameRus}: Вопросы ${selectedTest.questionsRange.start}-${selectedTest.questionsRange.end}`
                  )
              }
            </p>
            <p className={styles.question}>
              {language === Language.English ? `Question № ${questionIndex}` : `Вопрос № ${questionIndex}`}
            </p>
          </div>
        </article>
        <div className={styles.buttonCont}>
          <ToggleButton />
          {!isDifficultQuestion && !isModalOpen && <Timer />}
        </div>
        <article className={styles.mainTest}>
          <h2 className={styles.name}>{language === Language.English ? `${question.questionTextEn}` : `${question.questionTextRu}`}</h2>
          {question.image && loadImage(question.image) && <img src={loadImage(question.image)} alt="Illustration for question" className={styles.image} />}
          <ul className={styles.list}>
            {question.answers.map((answer) => (
              <li key={answer._id}>
                <div className={styles.listItem}>
                  <label className={styles.customLabel}>
                    <input
                      type="radio"
                      name="answer"
                      className={styles.hiddenRadio}
                      onChange={() => handleAnswerChange(answer._id!, answer.isCorrect)}
                      disabled={selectedAnswer !== null}
                    />
                    <CustomRadioButton
                      checked={selectedAnswer === answer._id || correctAnswerId === answer._id}
                      {...(selectedAnswer === answer._id && { icon: answer.isCorrect ? yesIcon : noIcon })}
                      {...(correctAnswerId === answer._id && { icon: yesIcon })}
                    />
                    <span className={styles.answerText}>
                      {language === Language.English ? answer.answerTextEn : answer.answerTextRu}
                    </span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
          {selectedAnswerComment && (
            <>
              <p className={styles.commentTitle}>{language === Language.English ? 'Explanation:' : 'Пояснение:'}</p>
              <span className={styles.comment}>
                {language === Language.English ? selectedAnswerComment.commentEn : selectedAnswerComment.commentRu}
              </span>
            </>
          )}
        </article>
      </div>
      <button
        className={`${styles.nextButton} ${isDisabledButton ? styles.disabled : ''}`}
        onClick={questionIndex === totalQuestionsQty ? onShowResults : onButtonClick}
        disabled={isDisabledButton || (questionIndex === totalQuestionsQty && selectedAnswer === null)}
      >
        {questionIndex === totalQuestionsQty
          ? (language === Language.English ? 'show results' : 'показать результаты')
          : (language === Language.English ? 'next question' : 'следующий вопрос')}
      </button>
    </div>
  );
};

export default Question;


