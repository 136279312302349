import { TIconProps } from "../../utils/types";

export const EditIcon = ({ onClick }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 178 178"
      fill="white"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.39,33.38a31.61,31.61,0,0,1-9.47,23.41q-46.72,46.9-93.65,93.59a16.92,16.92,0,0,1-6.8,3.83c-14.76,4.52-29.61,8.73-44.38,13.24-3.88,1.19-7.24,1-10.15-1.87S-.58,158.94.59,155c4.48-15.12,8.88-30.26,13.54-45.32A17,17,0,0,1,18.26,103q46.11-46.44,92.41-92.7C120.79.2,135-2.67,147.45,2.57,159.5,7.62,167.43,19.87,167.39,33.38Zm-56.19,5L38.41,111.18,57.12,130l72.82-72.83Zm34.05,4.52c5.42-5.76,5.41-13.74-.36-19.55a13.43,13.43,0,0,0-19.55-.19ZM23.57,144.83l14.61-4.38L28.17,129Z"
      />
    </svg>
  );
};
