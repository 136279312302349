export enum Language {
  English = 'eng',
  Russian = 'rus',
};

export enum Result {
  PassedEng = 'You passed!',
  PassedRus = 'Вы сдали!',
  FailedEng = `You didn't pass!`,
  FailedRus = 'Вы не сдали!',
  ResultsEng = 'Your Results',
  ResultsRus = 'Ваши результаты',
}

export const TIME_PER_QUESTION_MILLISEC = 60000;

export const PASSING_PERCENTAGE = 95;
export const ALMOST_PASSING_PERCENTAGE = 85;

export const API_URL = 'https://api.cdlmap.com/api'; //новый серверс США
// export const API_URL = 'http://localhost:4000/api'; // для тестирования


