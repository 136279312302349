import { Link } from 'react-router-dom';
import { useAppSelector } from '../..';
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { Language } from '../../utils/constants';
import styles from './ServiceRules.module.css';

const ServiceRules = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {language === Language.English ?
          (
            <>
              <h1 className={styles.title}>Service Rules</h1>
              <p className={styles.text}>This service is designed to assist in preparing for the CDL theoretical exam in the USA. The service does not guarantee that you will
                encounter identical question formulations on the exam. It is essential to understand the concept and theory, so a different question formulation
                will not cause you any confusion or misunderstanding.</p>
              <p className={styles.text}>Upon registration, you provide your email address, which must then be verified through a link sent to the email address you have provided.</p>
              <p className={styles.text}>We use essential cookies to enhance your experience with the application. It's important to note that a token for additional user
                verification is stored in the cookies.</p>
              <p className={styles.text}>Your email is stored in our database to ensure access to the application. You have the option to change your password and also to delete your
                account in the personal area.</p>
              <p className={styles.text}>We do not perform any actions with your email address other than to provide access to the service.</p>
            </>
          ) :
          (
            <>
              <h1 className={styles.title}>Правила сервиса</h1>
              <p className={styles.text}>Этот сервис создан для помощи в подготовке к теоретическому экзамену CDL в США. Сервис не гарантирует, что на экзамене
                Вы столкнетесь с идентичными формулировками вопросов. Вам нужно понять суть и теорию, тогда другая формулировка вопроса не вызовет
                у Вас тревоги и непонимания. </p>
              <p className={styles.text}>При регистрации вы предоставляете свою электронную почту, которую затем необходимо подтвердить через ссылку, отправленную на указанный
                Вами электронный адрес. Ваш электронный адрес хранится в нашей базе данных для обеспечения доступа к приложению. Мы используем обязательные
                Cookies для Вашего удобства использования приложения.  </p>
              <p className={styles.text}>Вы имеете возможность изменить свой пароль, а также удалить свой аккаунт в личном кабинете.</p>
              <p className={styles.text}>Мы не предпринимаем никаких действий с вашим электронным адресом, только обеспечиваем доступ к сервису.</p>
            </>
          )}
        <div>
          <Link to="/" className={styles.link}>
            {language === Language.English ? 'Return to main page' : 'Вернуться на главную'}
          </Link>
        </div>
        <div className={styles.toggle}>
          <ToggleButton />
        </div>
      </div>
    </div>
  );
};

export default ServiceRules;
