import { useEffect, useState } from 'react';
import styles from './ToggleButton.module.css';
import { Language } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../..';
import { setLanguage } from '../../redux/languageSelectSlice';

const ToggleButton = () => {
  const globalLanguage = useAppSelector(state => state.languageSelectReducer.language);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(globalLanguage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedLanguage(globalLanguage);
  }, [globalLanguage]);

  const handleToggle = () => {
    const newLanguage = selectedLanguage === Language.English ? Language.Russian : Language.English;
    dispatch(setLanguage(newLanguage));
  };

  return (
    <button
      className={`${styles.toggleButton} ${selectedLanguage === Language.English ? styles.eng_selected : styles.rus_selected}`}
      onClick={handleToggle}>
      <span className={styles.label}>
        {selectedLanguage === Language.English ? 'Eng' : 'Rus'}
      </span>
      <div className={styles.circle}></div>
    </button>
  );
};

export default ToggleButton;
