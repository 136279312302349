import React, { useCallback, useMemo, useRef } from 'react';
import { useState } from 'react';
import styles from './InputComponent.module.css';
import { TICons } from '../../ui/icons';
import * as Icons from '../../ui/icons';

interface TInputInterface extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  value: string;
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  success?: boolean;
  error?: boolean;
  disabled?: boolean;
  icon?: keyof TICons | null;
  errorText?: string;
  size?: 'default' | 'small';
  extraClass?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
  onBlur?(e?: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(e?: React.FocusEvent<HTMLInputElement>): void;
}

function useCombinedRefs<T = HTMLElement>(
  ...refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.MutableRefObject<T | null> {
  const targetRef = React.useRef<T>(null);
  React.useEffect(() => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);
  return targetRef;
}

export const Input = React.forwardRef<HTMLInputElement, TInputInterface>(
  (
    {
      type,
      placeholder,
      onChange,
      icon,
      onIconClick,
      success,
      error,
      value,
      errorText,
      disabled,
      onBlur,
      onFocus,
      size = 'default',
      extraClass = '',
      ...rest
    },
    forwardedRef
  ) => {
    const [focus, setFocus] = useState(false);
    const innerRef = useRef<HTMLInputElement>(null);
    const ref = useCombinedRefs<HTMLInputElement>(innerRef, forwardedRef);

    const handleInputFocus = useCallback(
      (e?: React.FocusEvent<HTMLInputElement>) => {
        setFocus(true);
        if (typeof onFocus === 'function') {
          onFocus(e);
        }
      },
      [setFocus, onFocus]
    );

    const forceFocus = useCallback(() => {
      ref?.current?.focus();
    }, [ref]);

    const handleInputBlur = useCallback(
      (e?: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
      },
      [setFocus, onBlur]
    );

    const onIconClickProxy = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (typeof onIconClick === 'function') {
          onIconClick(e);
        } else {
          forceFocus();
        }
      },
      [onIconClick, forceFocus]
    );

    const iconToRender = useMemo(() => {
      const Icon = icon && Icons[icon];
      const hasAction = typeof onIconClick === 'function';

      const iconClass = `${styles.input__icon} ${hasAction ? styles.input__icon_action : styles.input__icon_disabled}`;

      return Icon ? (
        <div
          className={iconClass}
          onClick={onIconClickProxy}
        >
          <Icon />
        </div>
      ) : null;
    }, [icon, onIconClickProxy, disabled, onIconClick]);

    const onWrapperClick = useCallback(() => {
      forceFocus();
    }, [forceFocus]);

    const errorToRender = useMemo(
      () =>
        error &&
        errorText && (
          <p className={styles.input__error}>
            {errorText}
          </p>
        ),
      [error, errorText, size]
    );

    return (
      <div className={`${styles.input__container} ${extraClass}`}>
        <div
          className={
            `${styles.input}
            ${type && `${styles.input_type}_${type}`}
            ${(size === 'small') ? styles.input_size_small : styles.input_size_default}
            ${error && styles.input_status_error}
            ${disabled && styles.input_status_disabled}
            ${focus && styles.input_status_active}
            `}
          onClick={onWrapperClick}
        >
          <label
            className={
              `${styles.input__placeholder}
               ${styles.text}
               ${styles.noselect}
               ${focus && styles.input__placeholder_focused}
               ${value && styles.input__placeholder_filled}
              ${disabled && styles.input__placeholder_disabled}
             `}
          >
            {placeholder}
          </label>

          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className={
              `${styles.text}
               ${styles.input__textfield}
               ${disabled && styles.input__textfield_disabled}
              `}
            type={type}
            ref={ref}
            onChange={onChange}
            value={value}
            disabled={disabled}
            {...rest}
          />
          {iconToRender}
        </div>
        {errorToRender}
      </div>
    );
  }
);

Input.displayName = 'Input';
