import styles from "./Modal-overlay.module.css";

export interface EventHandlerProps {
  onClick: ((e: React.MouseEvent) => void) | null;
};

export function ModalOverlay({ onClick }: EventHandlerProps) {
  return (
    <div className={styles.overlay} onClick={onClick ? onClick : undefined}>
    </div>
  );
};

