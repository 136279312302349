import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { IQuestions, ITest, ITestResultsProps, ITextResults, RouteParams } from '../../utils/types';
import Question from '../question/Question';
import { getNextTest, getQuestions, getSectionsById, getTestById } from '../../services/api';
import styles from './Questions.module.css';
import { Preloader } from '../preloader/Preloader';
import { useLocation, useParams } from 'react-router-dom';
import { setSection } from '../../redux/sectionSelectSlice';
import { resetAnsweredQuestions, resetTest, setTest, updateAnsweredQuestions } from '../../redux/testSelectSlice';
import { ALMOST_PASSING_PERCENTAGE, Language, PASSING_PERCENTAGE, Result } from '../../utils/constants';
import TestResults from '../test-results/TestResults';
import { useNavigate } from 'react-router-dom';
import AdSenseComponent from '../ad-sense/AdSenseComponent';
import { AdvertisingModal } from '../advertising-modal/AdvertisingModal';

const Questions = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<IQuestions | null>();
  const [loading, setLoading] = useState(true);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const answeredQuestionsQty = useAppSelector(state => state.testSelectReducer.answeredQuestionsQty);
  const { sectionId: urlSectionId } = useParams<RouteParams>();
  const sectionId = useAppSelector(state => state.sectionSelectReducer.id) || urlSectionId;
  const sectionEngName = useAppSelector(state => state.sectionSelectReducer.sectionNameEn);
  const { testId: urlTestId } = useParams<RouteParams>();
  const testId = useAppSelector(state => state.testSelectReducer.id) || urlTestId;
  const testNumber = useAppSelector(state => state.testSelectReducer.testNumber);
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState<ITextResults>();
  const [nextTest, setNextTest] = useState<ITest>();
  const [hasNextTest, setHasNextTest] = useState<boolean>(true);
  const location = useLocation();
  const timeExpired = useAppSelector((state) => state.timerReducer.timeExpired);
  const [showAdModal, setShowMAdodal] = useState(true);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const closeModal = () => {
    setShowMAdodal(false);
  };

  useEffect(() => {
    const handleLocationChange = () => {
      dispatch(resetAnsweredQuestions());
      dispatch(resetTest());
    };

    return handleLocationChange;
  }, [location, dispatch]);

  useEffect(() => {
    const fetchSection = async () => {
      try {
        if (!sectionEngName && sectionId) {
          const fetchedSection = await getSectionsById(sectionId);
          dispatch(setSection(fetchedSection.section));
        }
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    fetchSection();
  }, [dispatch, sectionEngName]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        if (!testNumber && testId && sectionId) {
          const fetchedTest = await getTestById(testId, sectionId);
          dispatch(setTest(fetchedTest.test));
        }
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    fetchTest();
  }, [dispatch, testNumber]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    setLoading(true)

    const fetchQuestions = async () => {
      try {
        const fetchedQuestions = await getQuestions(sectionId!, testId!);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setQuestions(fetchedQuestions);
          setLoading(false);
        }, 600);
      } catch (error) {
        console.error("Error fetching sections:", error);
        setLoading(false);
      }
    };

    fetchQuestions();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sectionId, testId]);

  const updateAnswerCounts = (isCorrect: boolean) => {
    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1);
    } else {
      setIncorrectAnswers(prev => prev + 1);
    }
    dispatch(updateAnsweredQuestions(answeredQuestionsQty + 1));
    setIsAnswerSelected(true);
  };

  const getLocalizedText = () => {
    return {
      questionsCount: questions?.questions.length ?? 0,
      correctAnswers: correctAnswers,
      incorrectAnswers: incorrectAnswers,
      requiredCorrectAnswers: 45,
      passMessage: {
        title: `${language === Language.English ? Result.PassedEng : Result.PassedRus}`,
        text: `${language === Language.English ? "Congratulations, you did great!" : "Поздравляем, Вы – молодец!"}`,
      },
      almostPassMessage: {
        tite: `${language === Language.English ? Result.FailedEng : Result.FailedRus}`,
        text: `${language === Language.English ? " You were just a little short. You've already prepared well. Try again." : "Чуть-чуть не хватило верных ответов. Попробуйте еще раз."}`,
      },
      failMessage: {
        tite: `${language === Language.English ? Result.FailedEng : Result.FailedRus}`,
        text: `${language === Language.English ? "Don't be discouraged. You'll succeed next time." : "Не отчаивайтесь, в следующий раз точно получится."}`,
      },
      timeIsUpMessage: {
        title: `${language === Language.English ? Result.FailedEng : Result.FailedRus}`,
        text: `${language === Language.English ? "Time's up. You didn't make it in time." : "Время вышло. Вы не уложились по времени."}`,
      },
    };
  };

  const handleNextTest = async () => {
    setLoading(true);
    try {
      if (testId) {
        const fetchedTest = await getNextTest(testId);

        if (fetchedTest && fetchedTest.test) {
          setNextTest(fetchedTest);
          setQuestions(null);
          dispatch(resetAnsweredQuestions());
          setCurrentQuestionIndex(0);
          setCorrectAnswers(0);
          setIncorrectAnswers(0);
          setShowResults(false);
          setHasNextTest(true);
          navigate(`/sections/${fetchedTest.test.sectionId}/${fetchedTest.test._id}/questions`);

          if (fetchedTest.test.sectionId !== sectionId) {
            const newSectionDetails = await getSectionsById(fetchedTest.test.sectionId!);
            dispatch(setSection(newSectionDetails.section));
          }
        } else {
          setHasNextTest(false);
        }
      }
    } catch (error) {
      setHasNextTest(false);
    } finally {
      setLoading(false);
    }
  };

  const handleRestartTest = () => {
    dispatch(resetAnsweredQuestions())
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setShowResults(false);
    setIsAnswerSelected(false);
    setCurrentQuestionIndex(0)
  };

  const setTestResults = ({ resultsData, message }: ITestResultsProps) => {
    setResults({
      results: resultsData,
      message: {
        title: message.title,
        text: message.text
      },
      hasNextTest: hasNextTest,
      onRestartTest: handleRestartTest,
      onNextTest: handleNextTest
    });
  };

  const handleShowResults = () => {

    if (questions?.questions.length) {
      setTimeout(() => {
        setLoading(true);

        setTimeout(() => {
          setLoading(false);
          setShowResults(true);

          const localizedText = getLocalizedText();
          const resultsData = {
            questionsCount: localizedText.questionsCount,
            correctAnswers: localizedText.correctAnswers,
            incorrectAnswers: localizedText.incorrectAnswers,
            requiredCorrectAnswers: localizedText.requiredCorrectAnswers
          };

          const correctAnswersPercentage = (correctAnswers / (questions?.questions.length || 1)) * 100;

          if (correctAnswersPercentage >= PASSING_PERCENTAGE) {
            setTestResults({
              resultsData: resultsData, message: {
                title: localizedText.passMessage.title,
                text: localizedText.passMessage.text
              }
            });
          }
          else if (correctAnswersPercentage >= ALMOST_PASSING_PERCENTAGE) {
            setTestResults({
              resultsData: resultsData, message: {
                title: localizedText.almostPassMessage.tite,
                text: localizedText.almostPassMessage.text
              }
            });
          } else {
            setTestResults({
              resultsData: resultsData, message: {
                title: localizedText.failMessage.tite,
                text: localizedText.failMessage.text
              }
            })
          }
        }, 1000);
      })
    };
  };

  useEffect(() => {
    if (nextTest) {
      navigate(`/sections/${nextTest.test.sectionId}/${nextTest.test._id}/questions`);
      dispatch(setTest(nextTest.test));

      if (nextTest.test.sectionId !== sectionId) {
        const updateSection = async () => {
          const newSectionDetails = await getSectionsById(nextTest.test.sectionId!);
          dispatch(setSection(newSectionDetails.section));
        };
        updateSection();
      }
    }
  }, [nextTest, navigate, dispatch, testId]);

  useEffect(() => {
    let preloaderTimeoutId: NodeJS.Timeout;;

    if (timeExpired) {
      preloaderTimeoutId = setTimeout(() => {
        setLoading(true);

        const resultsTimeoutId = setTimeout(() => {
          setLoading(false);
          setShowResults(true);

          const localizedText = getLocalizedText();
          const resultsData = {
            questionsCount: localizedText.questionsCount,
            correctAnswers: localizedText.correctAnswers,
            incorrectAnswers: localizedText.incorrectAnswers,
            requiredCorrectAnswers: localizedText.requiredCorrectAnswers
          };

          setTestResults({
            resultsData: resultsData,
            message: {
              title: localizedText.timeIsUpMessage.title,
              text: localizedText.timeIsUpMessage.text
            }
          });
        }, 1000);

        return () => clearTimeout(resultsTimeoutId);
      }, 1000);

      return () => clearTimeout(preloaderTimeoutId);
    }
  }, [timeExpired, language]);

  const nextQuestion = () => {
    if (isAnswerSelected) {
      setCurrentQuestionIndex(current => current + 1);
      setIsAnswerSelected(false);
    }
  };

  if (!questions || questions.questions.length === 0) {
    return <Preloader />;
  }

  if (showResults && results) {
    return (
      <div className={styles.resultsCont}>
        <TestResults
          results={results.results}
          message={results.message}
          onRestartTest={handleRestartTest}
          onNextTest={handleNextTest}
          hasNextTest={hasNextTest}
        />
      </div>
    );
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      {showAdModal &&
        <AdvertisingModal close={closeModal} delayCloseIcon={5000}>
          <AdSenseComponent />
        </AdvertisingModal>}
      <Question
        question={questions.questions[currentQuestionIndex]}
        questionIndex={currentQuestionIndex + 1}
        onAnswer={updateAnswerCounts}
        onShowResults={handleShowResults}
        onButtonClick={nextQuestion}
        isDifficultQuestion={false}
        isModalOpen={showAdModal}
      />
    </div>
  );
};

export default Questions;
