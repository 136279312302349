import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { ITestResultsProps, ITextResults, RouteParams } from '../../utils/types';
import Question from '../question/Question';
import styles from './DifficultQuestionsList.module.css';
import { useEffect, useState } from 'react';
import { updateAnsweredQuestions } from '../../redux/testSelectSlice';
import { Preloader } from '../preloader/Preloader';
import { Language, Result } from '../../utils/constants';
import TestResults from '../test-results/TestResults';
import { clearCurrentAnsweredQuestionsArray } from '../../redux/difficultQuestionsSlice';
import AdSenseComponent from '../ad-sense/AdSenseComponent';
import { AdvertisingModal } from '../advertising-modal/AdvertisingModal';

const DifficultQuestionsList = () => {
  const { sectionId, testId } = useParams<RouteParams>();
  const difficultQuestionsSections = useAppSelector(state => state.difficultQuestionsReducer.difficultQuestions?.sections);
  const difficultQuestionsTests = difficultQuestionsSections && difficultQuestionsSections.find((section) => section._id === sectionId)?.tests;
  const difficultQuestionsTest = difficultQuestionsTests?.find((test) => test._id === testId);
  const difficultQuestions = difficultQuestionsTest?.questions;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const answeredQuestionsQty = useAppSelector(state => state.testSelectReducer.answeredQuestionsQty);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [showResults, setShowResults] = useState(false);
  const location = useLocation();
  const [results, setResults] = useState<ITextResults>();
  const navigate = useNavigate();
  const selectedSectionId = useAppSelector(state => state.sectionSelectReducer.id);
  const selectedTestId = useAppSelector(state => state.testSelectReducer.id);
  const [showAdModal, setShowMAdodal] = useState(true);

  const closeModal = () => {
    setShowMAdodal(false);
  };

  useEffect(() => {
    if (selectedTestId === '' && selectedSectionId === '') {
      navigate('/difficult-questions');
    }

  }, [navigate, selectedTestId, selectedSectionId]);

  const updateAnswerCounts = (isCorrect: boolean) => {
    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1);
    } else {
      setIncorrectAnswers(prev => prev + 1);
    }
    dispatch(updateAnsweredQuestions(answeredQuestionsQty + 1));
    setIsAnswerSelected(true);
  };

  const getLocalizedText = () => {
    return {
      questionsCount: difficultQuestions?.length ?? 0,
      correctAnswers: correctAnswers,
      incorrectAnswers: incorrectAnswers,
      requiredCorrectAnswers: 45,
      passMessage: {
        title: `${language === Language.English ? Result.ResultsEng : Result.ResultsRus}`,
        text: '',
      },
    };
  };

  const handleShowResults = () => {

    if (difficultQuestions?.length) {
      setTimeout(() => {
        setLoading(true);

        setTimeout(() => {
          setLoading(false);
          setShowResults(true);

          const localizedText = getLocalizedText();
          const resultsData = {
            questionsCount: localizedText.questionsCount,
            correctAnswers: localizedText.correctAnswers,
            incorrectAnswers: localizedText.incorrectAnswers,
            requiredCorrectAnswers: localizedText.requiredCorrectAnswers,
            message: {
              title: localizedText.passMessage.title,
              text: localizedText.passMessage.text
            },
          }
          setTestResults({
            resultsData: resultsData, message: {
              title: localizedText.passMessage.title,
              text: localizedText.passMessage.text
            }
          });

        }, 1000);
      })
    };
  };

  const nextQuestion = () => {
    if (isAnswerSelected) {
      setCurrentQuestionIndex(current => current + 1);
      setIsAnswerSelected(false);
    }
  };

  const setTestResults = ({ resultsData, message }: ITestResultsProps) => {
    setResults({
      results: resultsData,
      message: {
        title: message.title,
        text: message.text
      },
    });
  };

  useEffect(() => {
    const handleLocationChange = () => {
      dispatch(clearCurrentAnsweredQuestionsArray());
    };

    return handleLocationChange;
  }, [location, dispatch]);

  if (showResults) {
    return (
      <div className={styles.resultsCont}>
        <TestResults
          results={results?.results!}
          message={results?.message!}
          isDifficultQuestions={true}
        />
      </div>
    );
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      {showAdModal &&
        <AdvertisingModal close={closeModal} delayCloseIcon={5000}>
          <AdSenseComponent />
        </AdvertisingModal>}
      {difficultQuestions &&
        <Question
          question={difficultQuestions[currentQuestionIndex]}
          questionIndex={currentQuestionIndex + 1}
          onAnswer={updateAnswerCounts}
          onShowResults={handleShowResults}
          onButtonClick={nextQuestion}
          isDifficultQuestion={true}
          key={difficultQuestions[currentQuestionIndex]._id}
        />}
    </div>
  );

};

export default DifficultQuestionsList;
