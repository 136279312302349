import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '..';
import { IProtectedRouteProps } from '../utils/types';
import { useEffect, useState } from 'react';
import { autoAuth } from '../redux/auth/authThunks';
import { Preloader } from './preloader/Preloader';
import Cookies from 'js-cookie';
import { logOut } from '../redux/auth/authSlice';

export function ProtectedRoute({ children }: IProtectedRouteProps) {
  const user = useAppSelector(store => store.authReducer.user);
  const isLoginRequesting = useAppSelector(store => store.authReducer.loginRequest);
  const accessToken = Cookies.get('accessToken');
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const refreshToken = localStorage.getItem('refreshToken');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((!refreshToken && !accessToken) || (!refreshToken && accessToken)) {
      dispatch(logOut());
    }
  }, [dispatch, refreshToken, accessToken]);

  useEffect(() => {
    if (!user) {
      dispatch(autoAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    timeoutId = setTimeout(() => {
      setLoading(false);
    }, 600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (loading) {
    return <Preloader />;
  }

  if (!user) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (isLoginRequesting) {
    return <Preloader />
  }

  return children;
};
