import { TIconProps } from "../../utils/types";

export const CancelIcon = ({ onClick }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 178 178"
      fill="white"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.52,88.72A88.76,88.76,0,1,1,0,88.58C.1,39.38,40.11-.18,89.58,0,137.88.18,177.61,40.25,177.52,88.72ZM101.16,89c1.15-1.23,2-2.15,2.83-3,8.64-8.67,17.32-17.3,25.94-26,4.11-4.15,4.23-9.19.48-12.91s-8.69-3.53-12.91.6-8.15,8.1-12.19,12.18C99.88,65.29,94.48,70.79,88.4,77c-1.62-2-2.75-3.66-4.14-5.07q-12-12.12-24.09-24.13C56,43.57,51,43.29,47.24,46.89s-3.77,8.84.48,13.18Q55.46,68,63.35,75.71C67.68,80,72.1,84.14,76.66,88.54c-9.93,9.91-19.36,19.19-28.61,28.64a13.63,13.63,0,0,0-3.43,6c-.86,3.59,1.36,7.2,4.73,8.94s7.11,1.25,10.27-1.87c8.7-8.61,17.32-17.3,26-25.95,1-1,2.1-2,3.26-3.11,9.91,9.93,19.48,19.61,29.17,29.17,3.76,3.71,8.89,3.63,12.38.15s3.62-8.55.06-12.41c-2.29-2.48-4.75-4.8-7.14-7.17Z"
      />
    </svg>
  );
};
