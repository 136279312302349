import Cookies from "js-cookie";
import { API_URL } from "../utils/constants";
import {
  IDifficultQuestions,
  IQuestions,
  ISection,
  ISectionsList,
  ITest,
  ITestsList,
  TCurrentAnsweredQuestion,
  TDefaultResponse,
  TDifficultQuestionsQty,
  TRefreshTokenResponse,
  TUser
} from "../utils/types";

const checkResponse = async <T>(res: Response): Promise<T> => {
  if (res.ok) {
    return res.json();
  } else {
    const err = await res.json();
    return await Promise.reject(err);
  }
};

export const getSections = (): Promise<ISectionsList> => {
  return fetchWithTokenRefresh<ISectionsList>(`${API_URL}/sections`, {
    method: "GET",
  });
};

export const getSectionsById = (sectionId: string): Promise<ISection> => {
  return fetchWithTokenRefresh<ISection>(`${API_URL}/sections/${sectionId}`, {
    method: "GET",
  });
};

export const getTests = (id: string): Promise<ITestsList> => {
  return fetchWithTokenRefresh<ITestsList>(`${API_URL}/sections/${id}/tests`, {
    method: "GET",
  });
};

export const getTestById = (testId: string, sectionId: string): Promise<ITest> => {
  return fetchWithTokenRefresh<ITest>(`${API_URL}/sections/${sectionId}/tests/${testId}`, {
    method: "GET",
  });
};

export const getQuestions = (sectionId: string, testId: string): Promise<IQuestions> => {
  return fetchWithTokenRefresh<IQuestions>(`${API_URL}/sections/${sectionId}/${testId}/questions`, {
    method: "GET",
  });
};

export const getNextTest = async (testId: string): Promise<ITest> => {
  return fetchWithTokenRefresh<ITest>(`${API_URL}/sections/tests/${testId}/next`, {
    method: "GET",
  });
};

export const getDifficultQuestionsQty = async (): Promise<TDifficultQuestionsQty> => {
  return fetchWithTokenRefresh<TDifficultQuestionsQty>(`${API_URL}/difficult-questions/qty`, {
    method: "GET",
  });
};

export const getDifficultQuestions = async (): Promise<IDifficultQuestions> => {
  return fetchWithTokenRefresh<IDifficultQuestions>(`${API_URL}/difficult-questions`, {
    method: "GET",
  });
};

export const updateDifficultQuestions = async (currentAnsweredQuestionsArray: TCurrentAnsweredQuestion[]): Promise<TDifficultQuestionsQty> => {
  return fetchWithTokenRefresh<TDifficultQuestionsQty>(`${API_URL}/difficult-questions/update`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(currentAnsweredQuestionsArray),
  },
  )
};

export async function createUser(email: string, password: string) {
  localStorage.removeItem('refreshToken');

  const res = await fetch(`${API_URL}/auth/register`, {
    method: "POST",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TDefaultResponse>(res);
};

export async function confirmRegistryByVarifyEmail(token: string) {
  localStorage.removeItem('refreshToken');

  const res = await fetch(`${API_URL}/auth/register/verify-email/${token}`, {
    method: "GET",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TUser>(res);
};

export async function logInItoAccount(email: string, password: string) {
  const res = await fetch(`${API_URL}/auth/signin`, {
    method: "POST",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password
    }),
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TUser>(res);
}

export async function logOutFromAccount() {
  Cookies.remove('accessToken');
  localStorage.removeItem('refreshToken');

  const res = await fetch(`${API_URL}/auth/signout`, {
    method: "POST",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TDefaultResponse>(res);
};

export async function getUserRequest() {
  return fetchWithTokenRefresh<TUser>(`${API_URL}/auth/user`, {
    method: "GET",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
};

export async function updateTokenRequest(): Promise<TRefreshTokenResponse | null> {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    console.error('No refresh token found');
    return null;
  }

  try {
    const res = await fetch(`${API_URL}/auth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-refresh-token": refreshToken
      }
    });

    if (!res.ok) {
      throw new Error('Failed to refresh token');
    }

    const refreshTokenResponse: TRefreshTokenResponse = await res.json();
    localStorage.setItem('refreshToken', refreshTokenResponse.refreshToken);
    Cookies.set('accessToken', refreshTokenResponse.accessToken);

    return refreshTokenResponse;
  } catch (error) {
    console.error('Error updating token:', error);
    return null;
  }
};

export async function updateUserPasswordAtServer(password: string): Promise<TDefaultResponse> {
  return fetchWithTokenRefresh<TDefaultResponse>(`${API_URL}/auth/user/update-password`, {
    method: "PATCH",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: password,
    }),
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
};

export async function deleteAccount(): Promise<TDefaultResponse> {
  return fetchWithTokenRefresh<TDefaultResponse>(`${API_URL}/auth/user/delete-account`, {
    method: "DELETE",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
};

async function fetchWithTokenRefresh<T>(url: string, options: RequestInit = {}): Promise<T> {
  let response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers as HeadersInit,
      'Authorization': `Bearer ${Cookies.get('accessToken')}`,
    },
  });

  if (response.status === 401 || response.status === 404) {

    try {
      const refreshTokenResponse = await updateTokenRequest();

      if (refreshTokenResponse && refreshTokenResponse.accessToken) {

        Cookies.set('accessToken', refreshTokenResponse.accessToken);

        const newOptions: RequestInit = {
          ...options,
          headers: {
            ...options.headers as HeadersInit,
            'Authorization': `Bearer ${refreshTokenResponse.accessToken}`,
          },
        }

        response = await fetch(url, newOptions);
      } else {
        if(Cookies.get('accessToken'))
        logOutFromAccount();
      }
    } catch (error) {
      console.error('Error updating token:', error);
    }
  }

  if (!localStorage.getItem('refreshToken')) {
    logOutFromAccount();
  }

  return checkResponse<T>(response);
};

export async function forgetPasswordServerRequest(email: string) {
  const res = await fetch(`${API_URL}/auth/password-reset`, {
    method: "POST",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TDefaultResponse>(res);
};

export async function verifyTokenOnServer(token: string) {
  const res = await fetch(`${API_URL}/auth/password-reset/reset/verify/${token}`, {
    method: "GET",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TDefaultResponse>(res);
};

export async function resetPasswordOnServer(password: string, token: string) {
  const res = await fetch(`${API_URL}/auth/password-reset/reset`, {
    method: "PATCH",
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: password,
      token: token
    }),
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  return checkResponse<TDefaultResponse>(res);
};
