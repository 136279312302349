import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "../utils/constants";

const initialState = {
  language: Language.Russian
};

const languageSelectSlice = createSlice({
  name: 'languageSelect',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
  }
});

export const { setLanguage } = languageSelectSlice.actions;

export default languageSelectSlice.reducer;
