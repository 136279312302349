import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { Language, Result, TIME_PER_QUESTION_MILLISEC } from '../../utils/constants';
import { ITextResults } from '../../utils/types';
import styles from './TestResults.module.css';
import { updateDifficultQuestions } from '../../services/api';
import { clearCurrentAnsweredQuestionsArray, setDifficultQuestionsQty } from '../../redux/difficultQuestionsSlice';
import { Link } from 'react-router-dom';
import { fetchDifficultQuestionsThunk } from '../../redux/difficultQuestionsThunks';

const TestResults = ({ results, message, onRestartTest, onNextTest, hasNextTest, isDifficultQuestions }: ITextResults) => {
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const sectionEngName = useAppSelector(state => state.sectionSelectReducer.sectionNameEn);
  const sectionRusName = useAppSelector(state => state.sectionSelectReducer.sectionNameRu);
  const test = useAppSelector(state => state.testSelectReducer);
  const timeLeft = useAppSelector(state => state.timerReducer.timeLeft);
  const questionsRange = useAppSelector(state => state.testSelectReducer.questionsRange);
  const questionsCount = questionsRange.end - questionsRange.start + 1;
  const user = useAppSelector(state => state.authReducer.user);
  const currentAnsweredQuestionsArray = useAppSelector(state => state.difficultQuestionsReducer.currentAnsweredQuestionsArray);

  const totalAllottedTimeInMinutes = (TIME_PER_QUESTION_MILLISEC * questionsCount) / 60000;
  const timeLeftInMinutes = timeLeft / 60000;
  const timeDifferenceInMinutes = Math.round(totalAllottedTimeInMinutes - timeLeftInMinutes);

  const totalAllottedTimeInSeconds = (TIME_PER_QUESTION_MILLISEC * questionsCount) / 1000;
  const timeLeftInSeconds = timeLeft / 1000;
  const timeDifferenceInSeconds = Math.round(totalAllottedTimeInSeconds - timeLeftInSeconds);

  const timeSpentInSeconds = totalAllottedTimeInSeconds - (timeLeft / 1000);

  const spentMinutes = Math.floor(timeSpentInSeconds / 60);
  const spentSeconds = Math.round(timeSpentInSeconds % 60);

  const formattedTimeSpentEng = `${spentMinutes > 0 ? `${spentMinutes} min ` : ''}${spentSeconds} sec`;
  const formattedTimeSpentRus = `${spentMinutes > 0 ? `${spentMinutes} мин ` : ''}${spentSeconds} сек`;

  const dispatch = useAppDispatch();

  const getTitleClass = () => {
    if (message.title === Result.FailedRus || message.title === Result.FailedEng) {
      return styles.failed;
    } else if (message.title === Result.PassedRus || message.title === Result.PassedEng) {
      return styles.passed;
    } else if (isDifficultQuestions && (message.title === Result.PassedRus || Result.ResultsEng)) {
      return styles.results;
    }
    return '';
  };

  useEffect(() => {
    dispatch(fetchDifficultQuestionsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (currentAnsweredQuestionsArray && currentAnsweredQuestionsArray.length !== 0) {
      updateDifficultQuestions(currentAnsweredQuestionsArray)
        .then((res) => {
          dispatch(clearCurrentAnsweredQuestionsArray());
          dispatch(setDifficultQuestionsQty(res.difficultQuestionsQty))
        })
        .catch(error => {
          console.error('Error updating difficult questions:', error);
        });
    }
  }, [currentAnsweredQuestionsArray, dispatch]);

  return (
    <div className={styles.resultsContainer}>
      {language === Language.English ? (
        <>
          <h1 className={`${styles.title} ${getTitleClass()}`}>{message.title}</h1>
          <h2 className={styles.text}>{message.text}</h2>
          <article className={styles.infoContainer}>
            <p className={styles.info}>{sectionEngName}</p>
            {!test.testName ? (<p className={styles.info}>TEST № {test.testNumber}</p>) : <p className={styles.info}>{test.testName.nameEng}</p>}
            <p className={styles.info}>Questions:&nbsp; {test.questionsRange.start}-{test.questionsRange.end}</p>
            <p className={styles.info}>{!isDifficultQuestions ? `Total questions: ` + "\u00A0" : `Total difficult questions: ` + "\u00A0"}
              <span className={styles.infoStyle}>{questionsCount}</span></p>
            <p className={styles.info}>Сorrect answers:&nbsp; <span className={styles.infoStyle}>{results.correctAnswers}</span></p>
            <p className={styles.info}>Incorrect answers:&nbsp; <span className={styles.infoStyle}>{results.incorrectAnswers}</span></p>
            {!isDifficultQuestions && <p className={styles.info}>Time Spent:&nbsp; {timeDifferenceInMinutes > 0 ?
              (<span className={styles.infoStyle}>
                {formattedTimeSpentEng}
              </span>) : (<span className={styles.infoStyle}>
                {`${timeDifferenceInSeconds} sec`}
              </span>)}</p>}
          </article>
          {!isDifficultQuestions && <div className={styles.buttons}>
            <button className={styles.button} type="button" onClick={onRestartTest}>restart test</button>
            {(hasNextTest && user?.status.type !== 'basic') && !test.testName ?
              (<button className={`${styles.button} ${styles.buttonColor}`} type="button" onClick={onNextTest}>next test</button>)
              : (<p className={styles.comment}>this was the last test of the section</p>)}
          </div>}
          {isDifficultQuestions && <Link to="/difficult-questions" className={styles.link}>Back to Difficult Questions Menu</Link>}
        </>
      ) : (
        <>
          <h1 className={`${styles.title} ${getTitleClass()}`}>{message.title}</h1>
          <h2 className={styles.text}>{message.text}</h2>
          <article className={styles.infoContainer}>
            <p className={styles.info}>{sectionRusName}</p>
            {!test.testName ? (<p className={styles.info}>ТЕСТ № {test.testNumber}</p>) : <p className={styles.info}>{test.testName.nameRus}</p>}
            <p className={styles.info}>Вопросы:&nbsp; {test.questionsRange.start}-{test.questionsRange.end}</p>
            <p className={styles.info}>{!isDifficultQuestions ? `Всего вопросов: ` + "\u00A0" : `Всего сложных вопросов: ` + "\u00A0"}
              <span className={styles.infoStyle}>{questionsCount}</span></p>
            <p className={styles.info}>Верных ответов:&nbsp; <span className={styles.infoStyle}>{results.correctAnswers}</span></p>
            <p className={styles.info}>Неверных ответов:&nbsp; <span className={styles.infoStyle}>{results.incorrectAnswers}</span></p>
            {!isDifficultQuestions && <p className={styles.info}>Потреченное время:&nbsp; {timeDifferenceInMinutes > 0 ?
              (<span className={styles.infoStyle}>
                {formattedTimeSpentRus}
              </span>) : (<span className={styles.infoStyle}>
                {`${timeDifferenceInSeconds} сек.`}
              </span>)}</p>}
          </article>
          {!isDifficultQuestions && <div className={styles.buttons}>
            <button className={styles.button} type="button" onClick={onRestartTest}>пройти снова</button>
            {(hasNextTest && user?.status.type !== 'basic') && !test.testName ?
              (<button className={`${styles.button} ${styles.buttonColor}`} type="button" onClick={onNextTest}>следующий тест</button>)
              : user?.status.type !== 'basic' && (<p className={styles.comment}>это был последний тест cекции</p>)}
          </div>}
          {isDifficultQuestions && <Link to="/difficult-questions" className={styles.link}>Назад к меню сложных вопросов</Link>}
        </>)}
    </div>
  );
};

export default TestResults;
