import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import Menu from '../menu/Menu';
import ToggleButton from '../toggle-button/ToggleButton';
import styles from './SectionList.module.css';
import { ISectionItem, ISectionsList } from '../../utils/types';
import { getSections } from '../../services/api';
import { Preloader } from '../preloader/Preloader';
import { setSection } from '../../redux/sectionSelectSlice';
import { useNavigate } from 'react-router-dom';

const SectionList = () => {
  const language = useAppSelector(state => state.languageSelectReducer.language);

  const name = language === Language.English ? 'Choose Section' : 'Выберите секцию';

  const [sections, setSections] = useState<ISectionsList>({ sections: [] });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onSectionClick = (item: ISectionItem) => {
    dispatch(setSection(item));
    navigate(`/sections/${item._id}/tests`);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const fetchSections = async () => {
      try {
        const fetchedSections = await getSections();

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setSections(fetchedSections);
          setLoading(false);
        }, 600);
      } catch (error) {
        console.error("Error fetching sections:", error);
        setLoading(false);
      }
    };

    fetchSections();

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <Menu name={name} items={sections} handleClick={onSectionClick}/>
      <ToggleButton />
    </div>
  );
};

export default SectionList;
