import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './ResetPassword.module.css';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Input } from '../../components/input/InputComponent';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import { validatePassword } from '../../utils/functions';
import PasswordValidator from '../../components/password-validator/PasswordValidator';
import { Button } from '../../components/button/ButtonComponent';
import ToggleButton from '../../components/toggle-button/ToggleButton';
import { verifyTokenOnServer } from '../../services/api';
import { Modal } from '../../components/modal/Modal';
import NotificationMessage from '../../components/notification-messsage/NotificationMessage';
import { Preloader } from '../../components/preloader/Preloader';
import { resetPassword } from '../../redux/auth/authThunks';

export const ResetPassword = () => {
  const { token } = useParams();
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const inputRefPassword = useRef<HTMLInputElement>(null);
  const inputRefRepeatPassword = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [containerHeight, setContainerHeight] = useState(window.innerHeight - 95);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [lastErrorType, setLastErrorType] = useState<'invalid' | 'server' | null>(null);
  const resetPasswordRequest = useAppSelector(state => state.authReducer.resetPasswordRequest);
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setShowModal(false);
  };
  const onChangePassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setPasswordValue(e.target.value);
  };

  const onChangeRepeatPassword = (e: { target: { value: SetStateAction<string>; }; }) => {
    setRepeatPasswordValue(e.target.value);
  };

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const actionResult = await dispatch(resetPassword({ password: passwordValue, token: token! }));

    if (actionResult.meta.requestStatus === 'fulfilled') {
      setError(false);
      setErrorMessage('');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/signin');
      }, 6000);
    }
    else if (actionResult.meta.requestStatus === 'rejected') {
      const errorInfo = actionResult.payload as { message: string, statusCode: number };;
      setError(true);

      if (errorInfo.statusCode === 401 || errorInfo.statusCode === 404) {
        setLastErrorType('invalid');
      } else {
        setLastErrorType('server');
      }
    } else {
      setError(false);
      setLastErrorType(null);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 95);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const result = await verifyTokenOnServer(token!);

        if (!result) {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        navigate('/sections');
      }
    };

    verifyToken();
  }, [useNavigate, useParams]);

  useEffect(() => {
    if (lastErrorType) {
      let message = '';
      switch (lastErrorType) {
        case 'invalid':
          message = language === Language.English ? 'Error in changing password' : 'Ошибка изменения пароля';
          break;
        case 'server':
          message = language === Language.English ? 'Server error, please try again later' : 'Ошибка сервера, попробуйте снова позже';
          break;
      }
      setErrorMessage(message);
    }
  }, [language, lastErrorType, error]);

  if (resetPasswordRequest) {
    return <Preloader />;
  }

  return (
    <div className={styles.page} style={{ height: containerHeight }}>
      {showModal &&
        (<Modal close={closeModal}>
          <NotificationMessage
            title={language === Language.English ? 'Password changed' : 'Пароль изменен'}
            text={language === Language.English ? 'Your password has been successfully changed. You will now be redirected to the login page.'
              : 'Ваш пароль успешно изменен. Сейчас вы будете перенаправлены на страницу входа.'} />
        </Modal>)}
      <div className={styles.mainContent}>
        <h2 className={styles.title}>{language === Language.English ? 'Create new password' : 'Создание нового пароля'}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <fieldset className={styles.inputCont}>
            <Input
              type={passwordInputType}
              placeholder={language === Language.English ? 'New password:' : 'Новый пароль:'}
              onChange={onChangePassword}
              value={passwordValue}
              name={'new-password'}
              error={error}
              errorText={errorMessage}
              ref={inputRefPassword}
              size={'default'}
              icon={passwordValue ? (passwordInputType === 'password' ? 'ShowIcon' : 'HideIcon') : null}
              onIconClick={togglePasswordVisibility}
              autoComplete="new-password"
            />
            {(validatePassword(passwordValue) && passwordValue !== '') &&
              <Input
                type={'password'}
                placeholder={language === Language.English ? 'Confirm new password:' : 'Подтвердите новый пароль:'}
                onChange={onChangeRepeatPassword}
                value={repeatPasswordValue}
                name={'repeatPasswordValue'}
                ref={inputRefRepeatPassword}
                size={'default'}
                onPaste={(e) => e.preventDefault()}
                icon={passwordValue === repeatPasswordValue && passwordValue !== '' ? 'CheckMarkIcon' : null}
                autoComplete="new-password"
              />}
            {(!validatePassword(passwordValue) && passwordValue !== '') &&
              <div className={styles.validationPart}>
                <h2 className={styles.validationPartTitle}>{language === Language.English ? 'Password requirements:' : 'Требования к паролю:'}</h2>
                <PasswordValidator password={passwordValue} />
              </div>}
          </fieldset>
          {(validatePassword(passwordValue) && passwordValue !== '') &&
            <Button
              disabled={passwordValue !== repeatPasswordValue || passwordValue === '' ? true : false}
              text={language === Language.English ? 'Save' : 'Сохраниь'}
              type='submit'
              buttonSize='big'
            />}
        </form>
        <div>
          <p className={styles.text}>{language === Language.English ? 'Remember your password?' : 'Вспомнили пароль?'}</p>
          <Link to="/signin" className={styles.link}>
            {language === Language.English ? 'Sign In' : 'Войти'}
          </Link>
        </div>
      </div>
      <div className={styles.toggle}>
        <ToggleButton />
      </div>
    </div>
  );
};

export default ResetPassword;
