import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./AdvertisingModal.module.css";
import { IModal } from "../../utils/types";
import { CloseIcon } from "../../ui/icons";
import { ModalOverlay } from "../modal-overlay/Modal-overlay";

const modalsContainer = document.querySelector("#modals") as HTMLElement;

export function AdvertisingModal({ close, children, delayCloseIcon = 0}: IModal & { delayCloseIcon?: number }) {

  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [countdown, setCountdown] = useState(delayCloseIcon / 1000);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (delayCloseIcon) {
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(intervalId);
            setShowCloseIcon(true);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } else {
      setShowCloseIcon(true);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [delayCloseIcon]);

  const handleEscKeydown = (evt: { key: string }) => {
    if (evt.key === 'Escape' && showCloseIcon) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeydown);

    return () => {
      document.removeEventListener("keydown", handleEscKeydown);
    };
  }, [showCloseIcon]);

  return ReactDOM.createPortal(
    <>
      <div className={styles.modal}>
        <div className={styles.close}>
          {showCloseIcon ? (
            <CloseIcon onClick={close} />
          ) : (
            <div className={styles.countdown}>{countdown}</div>
          )}
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
      <ModalOverlay onClick={showCloseIcon ? close : null} />
    </>,
    modalsContainer
  );
};
