import React, { useEffect, useRef, useState } from 'react';
import styles from './AdSenseComponent.module.css';

const AdSenseComponent: React.FC = () => {
  const adRef = useRef<HTMLDivElement>(null);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3947041381003081';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (adRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
            if (!isAdLoaded) {
              try {
                ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
                setIsAdLoaded(true);
              } catch (e) {
                console.error('Adsbygoogle push error', e);
              }
            }
          }
        }
      });

      resizeObserver.observe(adRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [isAdLoaded]);

  return (
    <div ref={adRef} className={styles.mainBlock}>
      <ins className="adsbygoogle"
        style={{ display: 'block', height: '100%', width: '100%', backgroundColor: 'white', flex: '1' }}
        data-ad-client="ca-pub-3947041381003081"
        data-ad-slot="1451200232"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <p className={styles.text}>Free CDL Tests in the USA</p>
    </div>
  );
};

export default AdSenseComponent;


