import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../..";
import Menu from "../menu/Menu";
import ToggleButton from "../toggle-button/ToggleButton";
import styles from "./DifficultTests.module.css";
import { ISectionItem, ITestItem, RouteParams } from "../../utils/types";
import { setTest } from "../../redux/testSelectSlice";
import { useEffect, useState } from 'react';
import { Preloader } from "../preloader/Preloader";

const DifficultTests = () => {
  const userStatus = useAppSelector(state => state.authReducer.user?.status.type);
  const selectedSection = useAppSelector(state => state.sectionSelectReducer);
  const difficultQuestions = useAppSelector(store => store.difficultQuestionsReducer.difficultQuestions);
  const { sectionId, testId } = useParams<RouteParams>();
  const difficultQuestionsSections = useAppSelector(state => state.difficultQuestionsReducer.difficultQuestions?.sections);
  const difficultQuestionsTests = difficultQuestionsSections && difficultQuestionsSections.find((section) => section._id === sectionId)?.tests;
  const difficultQuestionsTest = difficultQuestionsTests?.find((test) => test._id === testId);
  const selectedTestId = useAppSelector(state => state.testSelectReducer.id);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if ((sectionId && testId && !difficultQuestionsTest)) {
      navigate('/difficult-questions');
    }

    if (selectedTestId === '' && selectedSection.id === '') {
      navigate('/difficult-questions');
    }

  }, [navigate, sectionId, testId, difficultQuestionsTest, selectedTestId, selectedSection]);

  const onDifficultTestClick = (item: ITestItem) => {
    if (userStatus === 'basic' && item.testNumber !== 1) {
      return;
    }
    dispatch(setTest(item));
    navigate(`/difficult-questions/sections/${item.sectionId}/${item._id}/questions`);
  };

  if (!showContent) {
    return <Preloader />;
  }

  return (
    <div className={styles.mainContainer}>
      <Menu
        items={{
          tests: difficultQuestions?.sections.find((section: ISectionItem) => section._id === selectedSection.id)?.tests ?? [],
          sectionNameEn: selectedSection.sectionNameEn,
          sectionNameRu: selectedSection.sectionNameRu
        }}
        handleClick={onDifficultTestClick}
        totalQuestionsInTest={true}
      />
      <ToggleButton />
    </div>
  );
};

export default DifficultTests;
