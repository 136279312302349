import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../..";
import { Preloader } from "../../components/preloader/Preloader";
import ToggleButton from "../../components/toggle-button/ToggleButton";
import { Language } from "../../utils/constants";
import styles from "./DifficultQuestions.module.css";
import Menu from "../../components/menu/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { setSection } from "../../redux/sectionSelectSlice";
import { fetchDifficultQuestionsThunk } from "../../redux/difficultQuestionsThunks";
import { ISectionItem } from "../../utils/types";

const DifficultQuestions = () => {
  const difficultQuestionsQty = useAppSelector(store => store.difficultQuestionsReducer.difficultQuestionsQty);
  const language = useAppSelector(state => state.languageSelectReducer.language);
  const name = language === Language.English ? 'Choose Section' : 'Выберите секцию';
  const difficultQuestions = useAppSelector(store => store.difficultQuestionsReducer.difficultQuestions);
  const difficultQuestionsLoading = useAppSelector(store => store.difficultQuestionsReducer.difficultQuestionsLoading);
  const userStatus = useAppSelector(state => state.authReducer.user?.status.type);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const location = useLocation();
  const isInSectionsView = location.pathname === '/difficult-questions';

  const onDifficultSectionClick = (item: ISectionItem) => {
    dispatch(setSection(item));
    navigate(`/difficult-questions/sections/${item._id}/tests`);
  };

  useEffect(() => {
    dispatch(fetchDifficultQuestionsThunk());
  }, [dispatch, difficultQuestionsQty]);

  if (difficultQuestionsLoading || !showContent) {
    return <Preloader />;
  }

  return (
    <div className={styles.mainContainer}>
      {difficultQuestionsQty === 0 && userStatus === 'premium' &&
        <div className={styles.conatiner}>
          <h1 className={styles.title}>{language === Language.English ? 'No difficult questions' : 'Нет сложных вопросов'}</h1>
          <p className={styles.text}>{language === Language.English ?
            'Challenging questions will appear if you answer incorrectly.'
            : 'Сложные вопросы появятся, если вы ответите неверно.'}</p>
        </div>
      }
      {userStatus === 'basic' &&
        <div className={styles.conatiner}>
          <h1 className={styles.title}>{language === Language.English ? 'Difficult questions section' : 'Раздел сложных вопросов'}</h1>
          <p className={styles.text}>{language === Language.English ?
            'Challenging questions will appear if you answer incorrectly. This section will become available once you upgrade to Premium status.'
            : 'Сложные вопросы появятся, если вы ответите неверно. Этот раздел станет доступным, когда вы приобретете статус Premium.'}</p>
        </div>
      }
      {isInSectionsView && difficultQuestions && difficultQuestionsQty > 0 && userStatus === 'premium'
        && (<Menu name={name} items={difficultQuestions} handleClick={onDifficultSectionClick} totalQuestionsInSection={true} />)}
      <ToggleButton />
    </div>

  );
};

export default DifficultQuestions;
