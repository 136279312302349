import { useAppDispatch, useAppSelector } from '../..';
import { resetSection } from '../../redux/sectionSelectSlice';
import { resetTest } from '../../redux/testSelectSlice';
import { Language } from '../../utils/constants';
import { RouteParams } from '../../utils/types';
import styles from './BackButton.module.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { sectionId, testId } = useParams<RouteParams>();
  const language = useAppSelector(state => state.languageSelectReducer.language);

  const handleGoBack = () => {
    const pathArray = location.pathname.split('/');

    if (pathArray.includes('questions')) {
      dispatch(resetTest());
    }
    if (pathArray.includes('tests')) {
      dispatch(resetSection());
    }

    if (location.pathname === `/difficult-questions/sections/${sectionId}/${testId}/questions`) {
      navigate(-1);
      return;
    }

    if (location.pathname === '/difficult-questions') {
      navigate('/sections');
      return;
    }

    if (location.state?.from) {
      navigate(location.state.from);
      return;
    }

    navigate(-1);
  };

  return (
    <button className={styles.button} onClick={handleGoBack}>
      <div className={styles.image}></div>
      <h2 className={styles.text}>{language === Language.English ? 'Back' : 'Назад'}</h2>
    </button>
  );
};

export default BackButton;

