export const HideIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="white"
    >
      <path d="M19.1704 3.21413C19.4631 2.92862 19.9217 2.92862 20.2046 3.21413C20.4974 3.49964 20.4974 3.9722 20.2046 4.25771L18.4288 6.04952C19.8436 7.34907 21.0438 9.10149 21.9415 11.2083C22.0195 11.3954 22.0195 11.612 21.9415 11.7892C19.8534 16.6921 16.1358 19.6259 11.9987 19.6259H11.9889C10.1058 19.6259 8.30063 19.0056 6.71018 17.8735L4.81725 19.7834C4.67089 19.9311 4.4855 20 4.30011 20C4.11472 20 3.91957 19.9311 3.78297 19.7834C3.53903 19.5373 3.5 19.1435 3.69515 18.858L3.72442 18.8186L18.1556 4.25771C18.1751 4.23802 18.1946 4.21833 18.2044 4.19864C18.2239 4.17895 18.2434 4.15926 18.2532 4.13957L19.1704 3.21413ZM12.0013 3.38534C13.3966 3.38534 14.7529 3.72007 16.0018 4.35015L12.7429 7.63841C12.5087 7.59903 12.255 7.5695 12.0013 7.5695C9.84494 7.5695 8.09836 9.33177 8.09836 11.5075C8.09836 11.7635 8.12764 12.0195 8.16667 12.2558L4.55643 15.8984C3.5807 14.7564 2.7318 13.3781 2.05854 11.793C1.98049 11.6158 1.98049 11.3992 2.05854 11.2122C4.14662 6.30933 7.86419 3.38534 11.9916 3.38534H12.0013ZM15.2186 9.28855L14.1551 10.3617C14.3307 10.6964 14.4283 11.0902 14.4283 11.5037C14.4283 12.8525 13.3354 13.9551 11.9987 13.9551C11.5889 13.9551 11.1986 13.8567 10.8668 13.6795L9.80327 14.7526C10.4277 15.1759 11.1888 15.4319 11.9987 15.4319C14.1453 15.4319 15.8919 13.6696 15.8919 11.5037C15.8919 10.6865 15.6382 9.91863 15.2186 9.28855Z" />
    </svg>
  );
};
