import { useEffect, useRef, useState } from 'react';
import styles from './BurgerMenu.module.css';
import { useAppDispatch, useAppSelector } from '../..';
import { Language } from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import arrowIcon from '../../images/arrow.svg';
import { logOutFromAccount } from '../../services/api';
import { logOut } from '../../redux/auth/authSlice';
import { Preloader } from '../preloader/Preloader';

const BurgerMenu = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const difficultQuestionsCount = useAppSelector((state => state.difficultQuestionsReducer.difficultQuestionsQty));

  const language = useAppSelector(state => state.languageSelectReducer.language);

  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const navigate = useNavigate();

  const handeLogOut = async () => {
    closeMenu();
    setIsLoggingOut(true);
    setTimeout(async () => {
      try {
        const logout = await logOutFromAccount();
        dispatch(logOut());
        if (logout) {
          navigate(`/`);
        }
      } catch (error) {
        console.error('LogoutError', error);
      }
      setIsLoggingOut(false);
    }, 500);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div ref={menuRef}>
      {isLoggingOut && <Preloader />}
      <button className={styles.burgerButton} onClick={toggleMenu}>
        <div className={styles.burger}></div>
      </button>
      <div className={`${styles.menu} ${isOpen ? styles.open : ''}`}>
        <button className={styles.closeButton} onClick={toggleMenu}>
          <div className={styles.closeIcon}></div>
          <img className={styles.closeIcon} src={arrowIcon} alt='Arrow' />
        </button>
        <nav className={styles.burgerMenuCont}>
          <ul className={styles.burgerMenuList}>
            <li className={styles.burgerMenuListItem} onClick={closeMenu}>
              <Link to={'/user-profile'} className={`${styles.burgerMenuListItemText} ${styles.boldText}`}>
                {language === Language.English ? 'My account' : 'Мой аккаунт'}
              </Link>
            </li>
            <li className={styles.burgerMenuListItem} onClick={closeMenu}>
              <Link to={language === Language.English ? '/eng' : '/'} className={styles.burgerMenuListItemText}>
                {language === Language.English ? 'Home' : 'Главная'}
              </Link>
            </li>
            <li className={styles.burgerMenuListItem} onClick={closeMenu}>
              <Link to={'/sections'} className={styles.burgerMenuListItemText}>
                {language === Language.English ? 'Sections' : 'Секции'}
              </Link>
            </li>
            <li className={styles.burgerMenuListItem} onClick={closeMenu}>
              <Link to={'/difficult-questions'} className={styles.burgerMenuListItemText}>
                <div className={styles.burgerMenuListItemTextWithCount}>
                  <p className={styles.burgerMenuListItemTextWithCountText}>
                    {language === Language.English ? 'Difficult Questions' : 'Сложные вопросы'}
                    {difficultQuestionsCount > 0 && <span className={styles.burgerMenuListItemTextWithCountTextCount}>({difficultQuestionsCount})</span>}
                  </p>
                </div>
              </Link>
            </li>
          </ul>
          <button className={styles.exitButton} onClick={() => handeLogOut()}>
            {language === Language.English ? 'Sign out' : 'Выйти'}
          </button>
        </nav>
      </div>
    </div>
  );
}

export default BurgerMenu;


