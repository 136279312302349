import { Link, useNavigate } from 'react-router-dom';
import styles from './AboutEng.module.css';
import logo from '../../images/cdlMap_logo_black_background.svg';
import { useEffect, useRef, useState } from 'react';
import banner from '../../images/banner.webp';
import { CloseIcon } from '../../ui/icons';
import { Button } from '../../components/button/ButtonComponent';
import { Preloader } from '../../components/preloader/Preloader';
import CarouselComponent from '../../components/сarousel/CarouselComponent';
import FAQComponent from '../../components/faq/FAQComponent';
import instagramIcon from '../../images/instagram.svg';
import firstActionImage from '../../images/action1_eng.png';
import secondActionImage from '../../images/action2_eng.png';
import thirdActionImage from '../../images/action3_eng.png';
import fourthActionImage from '../../images/action4_eng.png';
import fifthActionImage from '../../images/action5_eng.png';
import sixthActionImage from '../../images/action6_eng.png';
import { Language } from '../../utils/constants';
import { useAppDispatch } from '../..';
import { setLanguage } from '../../redux/languageSelectSlice';

const AboutEng = () => {
  const contactsRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const howDoesItWorkRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleNavigate = (path: string) => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 700);
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const carouselItems = [
    {
      text: 'Register and confirm your email',
      tag: '1 step',
      pic: firstActionImage,
      step: 1
    },
    {
      text: 'Select a section',
      tag: ' 2 step',
      pic: secondActionImage,
      step: 2
    },
    {
      text: 'Choose a test',
      tag: ' 3 step',
      pic: thirdActionImage,
      step: 3
    },
    {
      text: 'Answer all the questions in the test',
      tag: ' 4 step',
      pic: fourthActionImage,
      step: 4
    },
    {
      text: 'See the number of correct and incorrect answers and the time it took to complete the test',
      tag: ' 5 step',
      pic: fifthActionImage,
      step: 5
    },
    {
      text: 'If you answer incorrectly, the question will go into the difficult questions',
      tag: ' 6 step',
      pic: sixthActionImage,
      step: 6
    }
  ];

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLanguage(Language.English));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  return (
    <main className={styles.main}>
      {isLoading && <Preloader />}
      <header className={styles.header}>
        <Link to={"/"}>
          <img className={styles.logo} src={logo} alt='СDL Map logo' />
        </Link>
        <nav className={`${styles.menuHeader}`}>
          <ul className={styles.menuListHeader}>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(aboutRef)}>
              About CDL Map
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(howDoesItWorkRef)}>
              How it works
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(faqRef)}>
              FAQ
            </li>
            <li className={styles.menuItemHeader} onClick={() => scrollToRef(contactsRef)}>
              Contacts
            </li>
          </ul>
        </nav>
        <div>
          <ul className={styles.menuAdditionalListHeader}>
            <li className={styles.menuAdditionalItemHeader}>
              <Link to="/signin">Sign in</Link>
            </li>
            <p className={styles.paragraph}>and</p>
            <li className={styles.menuAdditionalItemHeader}>
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
        </div>
        <div aria-label="Choose language">
          <ul className={styles.menuLanguageListHeader}>
            <li className={styles.languageHeaderItem}>
              <Link to="/" aria-label="Russian">Ru</Link>
            </li>
            <p className={styles.paragraph}>/</p>
            <li className={styles.languageHeaderItem}>
              <Link to="/eng" aria-label="English">En</Link>
            </li>
          </ul>
        </div>
        <button className={styles.burgerButton} onClick={toggleMenu}>
          <div className={styles.burger}></div>
        </button>
        <div className={`${styles.dropdownMenu} ${isMenuOpen ? styles.menuOpen : ''}`}>
          <button className={styles.closeButton} onClick={toggleMenu}>
            <div className={styles.closeIcon}></div>
            <CloseIcon />
          </button>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(aboutRef);
            setIsMenuOpen(false);
          }}>
            About CDL Map
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(howDoesItWorkRef);
            setIsMenuOpen(false);
          }}>
            How it works
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(faqRef);
            setIsMenuOpen(false);
          }}>
            FAQ
          </div>
          <div className={styles.dropdownMenuItem} onClick={() => {
            scrollToRef(contactsRef);
            setIsMenuOpen(false);
          }}>
            Contacts
          </div>
          <ul className={styles.dropdownMenuAuthList}>
            <li className={styles.dropdownMenuAuthListItem}>
              <Link to="/signin">Sign in</Link>
            </li>
            <li className={styles.dropdownMenuAuthListItem}>
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
          <div aria-label="Choose language">
            <ul className={styles.burgerLanguageListHeader}>
              <li className={styles.languageHeaderItem}>
                <Link to="/" aria-label="Russian">Ru</Link>
              </li>
              /
              <li className={styles.languageHeaderItem}>
                <Link to="/eng" aria-label="English">En</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <section className={styles.bannerSection}>
        <img className={styles.bannerImage} src={banner} alt='СDL Map banner' />
        <div className={styles.bannerTextContainer}>
          <h1 className={styles.mainTitle}>Start your&nbsp;<span className={styles.orangeText}>CDL</span> journey right now</h1>
          <p className={styles.mainSubTitle}>A&nbsp;service <span className={styles.orangeText}>for free preparation</span> for the CDL exam in&nbsp;the USA, specifically in&nbsp;the state of&nbsp;California</p>
          <Button onClick={() => handleNavigate('/signup')} text='Sign Up' buttonSize='big' type='submit' />
        </div>
      </section>
      <section className={styles.aboutSection} ref={aboutRef}>
        <div className={styles.sectionContainer}>
          <h2 className={styles.sectionTitle}>About CDL Map</h2>
          <p className={styles.sectionSubTitle}>To&nbsp;pass the CDL theoretical exam, you need to&nbsp;be&nbsp;very well-prepared.
            This service is&nbsp;designed to&nbsp;let you practice answering questions in&nbsp;a&nbsp;convenient test format.</p>
        </div>
        <ul className={styles.aboutSectionList}>
          <li className={styles.aboutSectionItem}>
            <p>
              Questions <span className={styles.orangeText}>in&nbsp;both russian and english</span> with an&nbsp;option for quick switching
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Only an&nbsp;email is&nbsp;needed</span> to&nbsp;register
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Tests</span> can be&nbsp;taken <span className={styles.orangeText}>on&nbsp;a&nbsp;smartphone or&nbsp;computer</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>A&nbsp;brief summary after</span> completing <span className={styles.orangeText}>the test</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Option</span> to&nbsp;take a&nbsp;<span className={styles.orangeText}>random test</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              Completely <span className={styles.orangeText}>free service</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Section with challenging questions</span> specifically troublesome for you
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Based on&nbsp;</span> information from <span className={styles.orangeText}>the official handbook</span>
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Explanations for answers</span> to&nbsp;enhance understanding
            </p>
          </li>
          <li className={styles.aboutSectionItem}>
            <p>
              <span className={styles.orangeText}>Timer</span> in&nbsp;every test
            </p>
          </li>
        </ul>
      </section>
      <section ref={howDoesItWorkRef} className={styles.howDoesItWorkSection}>
        <h2 className={`${styles.sectionTitle} ${styles.blackText}`}>How it works</h2>
        <ul className={styles.actionsList}>
          <CarouselComponent items={carouselItems} />
        </ul>
      </section>
      <section className={styles.faqSection} ref={faqRef}>
        <h2 className={styles.sectionTitle}>Frequently asked questions (FAQ)</h2>
        <ul className={styles.faqSectionList}>
          <FAQComponent
            question='Can I change my password in my account?'
            answer='Yes. You can change your password in the account settings. If you have forgotten your password and can
            not log in, you have the option to reset your password.' />
          <FAQComponent
            question='Are these the official DMV tests?'
            answer='These tests are based on the official guide, which can be downloaded from the DMV website. The exam may include similar questions
            with different wording. The main thing is to understand the essence and the theory; then, different wording will not be an issue.' />
          <FAQComponent
            question='Is this service free?'
            answer='Yes. This service provides free access to all tests.' />
          <FAQComponent
            question='Can i change the language during the test?'
            answer='Yes, you can.' />
          <FAQComponent
            question='Are there tests for other states?'
            answer='Currently, tests are available only for California. In the future, tests for other states may be introduced.' />
        </ul>
      </section>
      <section className={styles.contactsSection} ref={contactsRef}>
        <h2 className={styles.sectionTitle}>Contacts</h2>
        <p className={styles.sectionSubTitle}>For any questions, feel free to&nbsp;contact&nbsp;us at&nbsp;our email address:</p>
        <p className={styles.contactsEmail}>support@cdlmap.com</p>
      </section>
      <footer className={styles.footer}>
        <ul className={styles.menuListFooter}>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(aboutRef)}>About CDL Map</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(howDoesItWorkRef)}>How it works</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(faqRef)}>FAQ</li>
          <li className={styles.menuListFooterItem} onClick={() => scrollToRef(contactsRef)}>Contacts</li>
          <li className={styles.menuListFooterItem}>
            <Link to="/signup">Sign up</Link>
          </li>
          <li className={styles.menuListFooterItem}>
            <Link to="/signin">Sign in</Link>
          </li>
        </ul>
        <div className={styles.socialInfo}>
        <a href="https://www.instagram.com/cdlmap/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a className={styles.emailTextFooter} href="mailto:support@cdlmap.com">support@cdlmap.com</a>
        </div>
        <div className={styles.techText}>
          <p className={styles.copyright}>© 2024 CDL  Map</p>
          <Link to="/service-rules">Service rules</Link>
        </div>
      </footer>
    </main>
  );
}

export default AboutEng;
