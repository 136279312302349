export const validatePassword = (password: string) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>-]/.test(password);
  const isValidLength = password.length >= 12;

  return hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars && isValidLength;
};

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export function convertISODateToDateStrForRus(isoDateStr: string): string {
  const date = new Date(isoDateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export function convertISODateToLongFormatForEng(isoDateStr: string): string {
  const date = new Date(isoDateStr);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export const loadImage = (imagePath: string) => {
  try {
    return require(`../images/${imagePath}`);
  } catch (err) {
    return null;
  }
};
