import { Routes, Route } from 'react-router-dom';
import SectionList from '../section-list/SectionList';
import TestList from '../test-list/TestList';
import Questions from '../questions/Questions';
import Layout from '../layout/Layout';
import styles from './App.module.css';
import SignUp from '../../pages/sign-up/SignUp';
import { ProtectedRoute } from '../protected-route';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import SignIn from '../../pages/sign-in/SignIn';
import NotFound from '../../pages/not-found/NotFound';
import DifficultQuestions from '../../pages/difficult-questions/DifficultQuestions';
import { getDifficultQuestionsQtyThunk } from '../../redux/difficultQuestionsThunks';
import DifficultTests from '../difficult-tests/DifficultTests';
import DifficultQuestionsList from '../difficult-questionsList/DifficultQuestionsList';
import Cookies from 'js-cookie';
import UserProfile from '../../pages/user-profile/UserProfile';
import ForgotPassword from '../../pages/forgot-password/ForgotPassword';
import ResetPassword from '../../pages/reset-password/ResetPassword';
import ConfirmationEmailPage from '../../pages/confirmation-page/ConfirmationEmailPage';
import ServiceRules from '../../pages/service-rules/ServiceRules';
import About from '../../pages/about/About';
import AboutEng from '../../pages/about-eng/AboutEng';

function App() {
  const user = useAppSelector(store => store.authReducer.user);
  const loginSuccess = useAppSelector(store => store.authReducer.loginSuccess);
  const loginInProcess = useAppSelector(store => store.authReducer.loginRequest);

  const accessToken = Cookies.get('accessToken')

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loginSuccess && !loginInProcess) {
      if (accessToken) {
        dispatch(getDifficultQuestionsQtyThunk());
      }
    }
  }, [user, dispatch, loginSuccess]);

  return (
    <Routes>
      <Route path="/" element={<About />} />
      <Route path="/eng" element={<AboutEng />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/sections" element={
        <ProtectedRoute>
          <Layout>
            <div className={styles.main}>
              <SectionList />
            </div>
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/verify-email/:token" element={<ConfirmationEmailPage />} />
      <Route path="/service-rules" element={<ServiceRules />} />
      <Route path="sections/:sectionId/tests" element={<ProtectedRoute><Layout><TestList /></Layout></ProtectedRoute>} />
      <Route path="sections/:sectionId/:testId/questions" element={<ProtectedRoute><Layout><Questions /></Layout></ProtectedRoute>} />
      <Route path="/difficult-questions" element={<ProtectedRoute><Layout><DifficultQuestions /></Layout></ProtectedRoute>} />
      <Route path="/difficult-questions/sections/:sectionId/tests" element={<ProtectedRoute><Layout><DifficultTests /></Layout></ProtectedRoute>} />
      <Route path="/difficult-questions/sections/:sectionId/:testId/questions" element={<ProtectedRoute><Layout><DifficultQuestionsList /></Layout></ProtectedRoute>} />
      <Route path="/user-profile" element={<ProtectedRoute><Layout><UserProfile /></Layout></ProtectedRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
